import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
} from '@mui/material';
// components
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock
import { findHistory, byLicensePlate } from 'src/api/art';
// router
import { useNavigate } from 'react-router-dom';
// components
import Iconify from '../components/iconify';

// ---------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'dirittiautore', label: `Diritti d'autore`, alignRight: false },
  { id: 'dirittiproprieta', label: `Diritti di proprietà`, alignRight: false },
  { id: 'autoreautrice', label: `Autore/Autrice`, alignRight: false },
  { id: 'provenienza', label: `Provenienza`, alignRight: false },
  { id: 'tipologia', label: `Tipologia`, alignRight: false },
  { id: 'createdat', label: 'Data della modifica', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function timestampToDateAndTime(timestamp) {
  if (timestamp == null) {
    return "-";
  }
  const date = new Date(timestamp);
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'Europe/Rome' };
  const formattedDate = new Intl.DateTimeFormat('it-IT', options).format(date);
  return formattedDate;
}

export default function HistoryPage() {

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('createdAt');

  const [rowsPerPage, setRowsPerPage] = useState(30);

  const [AssetList, setAssetList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    //
  };

  const handleSelectAllClick = (event) => {
    //
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    sessionStorage.setItem('rowsPerPage', event.target.value);
  };

  const handleBackButton = () => {
    navigate("/dashboard/opere");
  }

  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const load = async () => {
      var _list = await findHistory(searchParams.get('id'), page, 30, order, orderBy);
      setTotal(_list.headers["x-total-count"]);
      if (_list) {
        const asset = await byLicensePlate(searchParams.get('opera'));
        var list = _list.data.filter(a => a !== null);
        // append asset as first element of the list
        list.unshift(asset);
        setAssetList(list);
        setLoading(false);
      }
    }
    load();
  }, []);

  useEffect(() => {
    const load = async () => {
      var _list = await findHistory(searchParams.get('id'), page, rowsPerPage, order, orderBy);
      setTotal(_list.headers["x-total-count"]);
      if (_list) {
        setAssetList(_list.data.filter(a => a !== null));
      }
    }
    load();
  }, [page, rowsPerPage, order, orderBy]);

  return (
    <>
      <Helmet>
        <title> Storico Opere | Registro Arte </title>
      </Helmet>

      <Button onClick={handleBackButton} sx={{ mb: 2 }}>
        <Iconify icon={'icon-park-outline:back'} sx={{ mr: 2 }} />
        TORNA INDIETRO
      </Button>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Storico Opera {searchParams.get('opera')}
          </Typography>
        </Stack>

        {loading ? (
          <img src="/assets/icons/Pulse-1s-200px.svg" />
        ) : (
          <Card>
            <Scrollbar>
              <TableContainer>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={AssetList.length}
                    numSelected={0}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {AssetList.map((row) => {
                      const { id, dirittiAutore, dirittiProprieta, createdAt, autore, provenienza, tipologia } = row;
                      const selectedAsset = selected.indexOf(id) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedAsset}>

                          <TableCell align="left">{dirittiAutore}</TableCell>

                          <TableCell align="left">{dirittiProprieta}</TableCell>

                          <TableCell align="left">{autore}</TableCell>

                          <TableCell align="left">{provenienza}</TableCell>

                          <TableCell align="left">{tipologia}</TableCell>

                          <TableCell align="left">{timestampToDateAndTime(createdAt)}</TableCell>

                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[30, 60, 80]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>

    </>
  );
}
