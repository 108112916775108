import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Card, FormControl, Stack, TextField, Container, Typography, Button, Divider } from '@mui/material';
import { AppCardSummary } from 'src/sections/@dashboard/app';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// components
// sections
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import useResponsive from 'src/hooks/useResponsive';
import { useState } from 'react';
import { checkLogin, resetFinish, resetInit } from 'src/api/auth';
import { useUser } from 'src/layouts/dashboard/DashboardLayout';
import { changeAnagrafica, changeEmail } from 'src/api/user';
// hooks
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function SettingsPage() {
  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');

  const [pwd, setPwd] = useState('');
  const [confermaPwd, setConfermaPwd] = useState('');
  const [nuovaPwd, setNuovaPwd] = useState('');

  const [nuovaEmail, setNuovaEmail] = useState('');
  const [pwdNuovaEmail, setPwdNuovaEmail] = useState('');

  const [errPwd, setErrPwd] = useState('');
  const [msgPwd, setMsgPwd] = useState('');
  const [errEmail, setEmail] = useState('');

  const [newBirthDay, setNewBirthDay] = useState('');
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [newFiscalCode, setNewFiscalCode] = useState('');

  const navigate = useNavigate();

  const { user } = useUser();

  const handlePwd = (event) => {
    setPwd(event.target.value);
  }

  const handleConfermaPwd = (event) => {
    setConfermaPwd(event.target.value);
  }

  const handleNuovaPwd = (event) => {
    setNuovaPwd(event.target.value);
  }

  const handleNuovaEmail = (event) => {
    setNuovaEmail(event.target.value);
  }

  const handlePwdNuovaEmail = (event) => {
    setPwdNuovaEmail(event.target.value);
  }

  const handleNewFirstName = (event) => {
    setNewFirstName(event.target.value);
  }

  const handleNewLastName = (event) => {
    setNewLastName(event.target.value);
  }

  const handleNewFiscalcode = (event) => {
    setNewFiscalCode(event.target.value);
  }

  const handleBirthDayChange = (value) => {
    var date = new Date(value);

    // Get year, month, and day part from the date
    var year = date.toLocaleString("default", { year: "numeric" });
    var month = date.toLocaleString("default", { month: "2-digit" });
    var day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    var formattedDate = year + "-" + month + "-" + day;
    setNewBirthDay(formattedDate);
  }

  const handleConfermaPwdClick = async (event) => {
    if (pwd === confermaPwd && user != null) {
      const valid = await checkLogin(user.login, pwd, false);
      if (valid) {
        const token = await resetInit(user.login);
        await resetFinish(token, nuovaPwd);
        setMsgPwd('La password è stata modificata con successo. Si prega di utilizzare la nuova password al momento di un nuovo accesso.');
      } else {
        setErrPwd('La password inserita è errata.');
      }
    } else {
      setErrPwd('Le password inserite non corrispondono.');
    }
  }

  const handleConfermaEmailClick = async (event) => {

    const valid = await checkLogin(user.login, pwdNuovaEmail, false);
    if (valid) {
      await changeEmail(user.login, nuovaEmail);
      setMsgPwd('La email è stata aggiornata con successo. Si prega di utilizzare la nuova email al momento di un nuovo accesso.');
    } else {
      setErrPwd('La password inserita è errata.');
    }

  }

  const handleConfermaAnagraficaClick = async (event) => {

    await changeAnagrafica(user.person.id, newFirstName, newLastName, newBirthDay, newFiscalCode)
      .then().finally(() => {
        navigate(0);
      });
  }

  const body = <>
    {user != null ?
      <Typography variant="body2">
        NOME : {user.person.firstName} <br />
        COGNOME : {user.person.lastName} <br />
        EMAIL : {user.login} <br />
        RUOLO : {user.authorities[0]} <br />
        DATA DI NASCITA : {new Date(user.person.birthDay).toLocaleDateString('it-IT', { year: "numeric", month: "short", day: "numeric" })} <br />
        CODICE FISCALE : {user.person.fiscalCode} <br />
      </Typography>
      : <>..</>}
  </>


  useEffect(() => {
    if (user != null) {
      setNewFirstName(user.person.firstName);
      setNewLastName(user.person.lastName);
      setNewBirthDay(user.person.birthDay);
      setNewFiscalCode(user.person.fiscalCode);
    }
  }, [user]);

  return (
    <>
      <Helmet>
        <title> Gestione Utente | Registro Arte </title>
      </Helmet>

      <Container>
        <Card sx={{ padding: 4 }}>
          <Scrollbar>
            <Typography justifyContent='center' variant="h4" gutterBottom>
              Gestione dell' Utente
            </Typography>

            <AppCardSummary title="Informazioni Utente" body={body} ></AppCardSummary>

            <FormControl sx={{ width: '100%' }}>
              <Stack mt={1} spacing={2} >

                <Typography color='text.secondary'>Cambio password</Typography>
                <Typography variant='body2' color='text.primary'>Inserisci password</Typography>
                <TextField type='password' onChange={handlePwd} />
                <Typography variant='body2' color='text.primary'>Conferma password</Typography>
                <TextField type='password' onChange={handleConfermaPwd} />
                <Typography variant='body2' color='text.primary'>Nuova password</Typography>
                <TextField type='password' onChange={handleNuovaPwd} />

                {errPwd !== '' ? (
                  <span style={{ color: "#FF4842" }} >{errPwd}</span>
                ) : (
                  <></>
                )}

                {msgPwd !== '' ? (
                  <span >{msgPwd}</span>
                ) : (
                  <></>
                )}

                <Button onClick={handleConfermaPwdClick} color='error' sx={{ display: 'flex', mt: 4, ml: '85%' }} variant="contained">
                  Conferma cambio password
                </Button>

                <Divider />

                <Typography color='text.secondary'>Cambio email</Typography>
                <Typography variant='body2' color='text.primary'>Inserisci nuova email</Typography>
                <TextField onChange={handleNuovaEmail} />
                <Typography variant='body2' color='text.primary'>Inserisci password</Typography>
                <TextField onChange={handlePwdNuovaEmail} />

                {errEmail !== '' ? (
                  <span style={{ color: "#FF4842" }} >{errEmail}</span>
                ) : (
                  <></>
                )}

                <Button onClick={handleConfermaEmailClick} color='error' sx={{ display: 'flex', mt: 4, ml: '85%' }} variant="contained">
                  Conferma cambio email
                </Button>

                <Divider />

                <Typography color='text.secondary'>Cambio anagrafica</Typography>
                <Typography variant='body2' color='text.primary'>Inserisci nome (opzionale)</Typography>
                <TextField onChange={handleNewFirstName} />
                <Typography variant='body2' color='text.primary'>Inserisci cognome (opzionale)</Typography>
                <TextField onChange={handleNewLastName} />
                <Typography variant='body2' color='text.primary'>Inserisci data di nascita (opzionale)</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format='DD/MM/YYYY'
                    name="birthDay" label="Data di nascita"
                    onChange={handleBirthDayChange}
                  />
                </LocalizationProvider>
                <Typography variant='body2' color='text.primary'>Inserisci codice fiscale (opzionale)</Typography>
                <TextField onChange={handleNewFiscalcode} />

                <Button onClick={handleConfermaAnagraficaClick} color='error' sx={{ display: 'flex', mt: 4, ml: '85%' }} variant="contained">
                  Conferma cambio anagrafica
                </Button>

              </Stack>
            </FormControl>

          </Scrollbar>

        </Card>

      </Container>

    </>
  );
}
