import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Grid, Box, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
// style
import './style/home.css';
// router
import { useNavigate } from 'react-router-dom';
// image gallery
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

// ------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const images = [
    {
        original: "assets/illustrations/sculpture.jpg",
    },
    {
        original: "assets/illustrations/background.jpg",
    },
    {
        original: "assets/illustrations/mosaic.jpg",
    },
    {
        original: "assets/illustrations/painting.jpg",
    },
    {
        original: "assets/illustrations/cross.jpg",
    },
];


export default function HomePage() {

    const mdUp = useResponsive('up', 'md');
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/login', { replace: true });
    }

    const handleClickSignup = () => {
        navigate('/register', { replace: true });
    }

    return (
        <>
            <Helmet>
                <title>Home | Registro Arte</title>
            </Helmet>

            <StyledRoot>
                <Box >

                    <Grid container spacing={4} padding={3}
                        alignItems="center">

                        {
                            mdUp ?
                                <>
                                    <Grid item xs={4} ><Logo /></Grid>
                                    <Grid item xs={5} >
                                        <Typography variant="h6" sx={{ paddingLeft: 3, color: 'text.secondary' }}>
                                            Tracciabilità dei beni culturali e opere
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} ><Button size="large" type="reset" variant="contained" onClick={handleClick} >ACCEDI</Button></Grid>
                                    <Grid item xs={1} ><Button size="large" type="reset" variant="outlined" onClick={handleClickSignup} >REGISTRATI</Button></Grid>

                                </>
                                :
                                <>
                                    <Grid sx={{ display: 'flex' }} justifyContent='center' item xs={12} ><Logo /></Grid>
                                    <Grid sx={{ display: 'flex' }} justifyContent='center' item xs={12} >
                                        <Typography variant="h6" sx={{ paddingLeft: 3, color: 'text.secondary' }}>
                                            Tracciabilità dei beni culturali e opere
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ display: 'flex' }} justifyContent='center' item xs={6} ><Button size="large" type="reset" variant="contained" onClick={handleClick} >ACCEDI</Button></Grid>
                                    <Grid sx={{ display: 'flex' }} justifyContent='center' item xs={6} ><Button size="large" type="reset" variant="outlined" onClick={handleClickSignup} >REGISTRATI</Button></Grid>

                                </>
                        }
                    </Grid>
                    <Box />

                    <ImageGallery
                        autoPlay={true}
                        showNav={false}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showIndex={false}
                        showBullets={false}
                        showPlayButton={false}
                        items={images} />

                    <div style={{ paddingLeft: '8%', paddingRight: '8%' }} >
                        <Stack padding={2} spacing={2} justifyContent="center">


                            <Typography variant="h5" sx={{ color: 'text.secondary' }}>
                                RegistroArte è una raccolta dati di beni culturali ed opere d’arte, con documento digitale basato sul modello del Registro dei titolari effettivi previsto già dalla V direttiva AML.
                                Un annuario delle opere di cui si compone il patrimonio artistico-culturale, di proprietà pubblica e privata.

                            </Typography>
                            <Divider />
                            <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                A seguito della registrazione dell’opera e delle relative informazioni, la piattaforma genera un attestato digitale di con tutte le caratteristiche della stessa. L’opera validata da un esperto o da un catalogatore professionista, viene registrata, identificata con un Passaporto digitale (numero ID), nel pieno rispetto del GDPR, e generato l'attestato digitale (NFT) autenticato su blockchain pubblica. Sulle opere viene applicato un TAG antieffrazione, non rimovibile (RFid-NFC) con link digitale alle caratteristiche della stessa. RegistroArte mette in luce le movimentazioni aventi ad oggetto i beni culturali, evidenziando, tracciando e storicizzando i diversi passaggi di titolarità/possesso delle opere. RegistroArte mette a disposizione di artisti, operatori del settore e titolari, uno strumento per tutelare la paternità e l'evoluzione della propria opera apportando innovative soluzioni tecnologico-digitale. L’ utilizzo di tecnologia blockchain nella sua natura di registro Pubblico distribuito è sinonimo di trasparenza e disponibilità di lettura a tutti e consente di dimostrarne la storicizzazione. Tramite “smart contract” Pubblico, una volta inseriti i dati dell’opera, viene mintato un NFT cioè la rappresentazione digitale di un asset fisico.
                            </Typography>

                            <Divider />


                        </Stack>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center" sx={{
                                backgroundColor: 'primary.dark',
                            }} >
                            <img style={{ padding: 10, width: '50%', height: '50%' }} src={`/assets/illustrations/registroartehome2.png`} alt="" />

                        </Box>

                        <Stack padding={2} spacing={2} justifyContent="center">
                            <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                L'Osservatorio Italia Antiriciclaggio per l'Arte ha come obiettivi preminenti il monitoraggio e l'analisi dei fenomeni di finanziamento del terrorismo, di riciclaggio e di autoriciclaggio di danaro, beni o altre utilità provenienti da delitto.
                            </Typography>

                            <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                Oggetto di interesse sono le dinamiche di riciclaggio e di finanziamento del terrorismo e delle distorsioni che ne derivano con particolare riguardo al mercato dell'arte. Difatti molteplici sono le ripercussioni, sul patrimonio artistico e culturale, che rappresentano una minaccia concreta al percorso antropologico di evoluzione fatto di memoria, tradizioni e genio attraverso i quali l'umanità continua ad esprimere i suoi aneliti più profondi.
                            </Typography>

                            <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                Sin dalle origini, l'uomo ha sempre avvertito l'esigenza di rendere identificabili e riconducibili a sé le proprie opere, nella spasmodica, testarda, ingenua, affermazione dell'Io. La tecnologia viene messa a disposizione dell'artista che desidera tutelare la paternità e l'evoluzione della propria opera, conoscenze e supporti infinitamente più evoluti rispetto al passato, che ne permettono la sua tracciabilità nel tempo.
                            </Typography>
                            <Divider />

                            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                VISION
                            </Typography>
                            <Divider />
                            <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                Crediamo che sia propedeutico acquisire piena consapevolezza del valore artistico e culturale di quei beni materiali, opere d’arte, nonché di un bene immateriale, ma altrettanto prezioso, che è la compliance dei professionisti, delle imprese e, più in generale, dei soggetti che interagiscono nel mercato dell’arte.
                            </Typography>

                            <Typography variant="body1" sx={{ color: 'text.primary' }}>
                                Assicurando la tracciabilità delle opere d’arte, saremo in grado di monitorare le sottostanti movimentazioni di denaro e intercettare quelle che sono provento di attività illecite.
                            </Typography>
                            <Divider />
                            <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                                Far Beyond Trust. Ben oltre la fiducia
                            </Typography>

                            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                La Blockchain è una tecnologia simile ad un database con caratteristiche di immutabilità, sicurezza e garanzia dei dati. È riconosciuta dall’Ordinamento Giuridico Italiano nel Decreto Semplificazioni del 7 Febbraio 2019                        </Typography>


                        </Stack>
                    </div>
                    <Box
                        display="flex"
                        padding={1}
                        justifyContent="flex-start"
                        alignItems="flex-start" sx={{
                            backgroundColor: 'primary.dark',
                        }} >

                        <Stack flex={1} spacing={2} padding={4}>

                            <img style={{ width: 240 }} src="/assets/icons/logo_osservatorio_registro_arte.png" alt="" />
                            <Typography variant="subtitle1" sx={{ color: 'common.white' }}>
                                OSSERVATORIO ITALIA ANTIRICICLAGGIO PER L'ARTE - C.F. 96515970588 - 00137 Roma (RM)
                            </Typography>
                            <Divider sx={{ borderColor: 'common.white' }} />
                            <Typography variant="body2" sx={{ color: 'common.white' }}>
                                Registro Arte è una piattaforma, di proprietà intellettuale di Solidity2 Srl, in licenza all’Osservatorio Italia Antiriciclaggio per l'Arte il quale ha come obiettivo la tracciabilità dei beni culturali ed opere d’arte per finalità di antiriciclaggio, anticontraffazione e diritti di seguito.
                            </Typography>

                            <Typography sx={{ color: 'common.white' }}>
                                <a style={{ color: 'white' }} target='blank' href='https://registroarte.it/assets/Condizioni_Generali.pdf' >Termini e condizioni</a>
                            </Typography>
                            <Divider sx={{ borderColor: 'common.white' }} />
                            <img style={{ width: 169, height: 19 }} src="/assets/icons/logo_solidity_registroarte.png" alt="" />
                            <Typography variant="subtitle1" sx={{ color: 'common.white' }}>
                                © Copyright {new Date().getFullYear()} - Solidity2 srl - Startup Innovativa - P.IVA: 02454210440 – REA: AP-273622

                            </Typography>
                            <Typography sx={{ color: 'common.white' }}>
                                Email  :<a style={{ color: 'white' }} href='mailto:info@solidity2.it' >info@solidity2.it</a>
                            </Typography>
                        </Stack>
                    </Box>

                    {localStorage.getItem('cookie') === 'true' ? null :
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center" sx={{
                                backgroundColor: 'primary.dark',
                            }} >
                            <Stack id='myBtn' padding={2} spacing={2}>
                                <p>
                                    Questo sito utilizza i cookie per migliorare i servizi e ottimizzare l’esperienza di navigazione dell’utente. I cookie di natura tecnica sono indispensabili per permettere il corretto funzionamento del sito. Non utilizziamo altri tipi di cookie. Per saperne di più consulta la relativa informativa sul trattamento dei dati sulle
                                    <a style={{ color: 'white' }} target='_blank' href="https://www.registroarte.it/privacy-policy" > informazioni sulla privacy</a>.
                                </p>
                                <button
                                    className='center'
                                    id='cookieBtn'
                                    onClick={() => {
                                        localStorage.setItem('cookie', 'true');
                                        window.location.reload();
                                    }}
                                >
                                    Accetta e chiudi
                                </button>
                            </Stack>
                        </Box>
                    }

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center" sx={{
                            backgroundColor: 'primary.darker',
                        }} >

                        <Stack padding={2} spacing={2}>

                            <Link target="_blank" underline='hover' href="https://www.registroarte.it/privacy-policy" sx={{ color: 'common.white' }} >
                                PRIVACY
                            </Link>

                        </Stack>
                    </Box>
                </Box>
            </StyledRoot>
        </>
    )
}