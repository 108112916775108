import { Helmet } from 'react-helmet-async';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Link, Grid, Box, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
// style
import './style/home.css';
// router
import { useNavigate } from 'react-router-dom';
// ------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));


export default function PrivacyPage() {

    const mdUp = useResponsive('up', 'md');
    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>Home | Registro Arte</title>
            </Helmet>

            <StyledRoot>
                <Box >
                    <Grid container padding={3}
                        alignItems="center">

                        <Grid item xs={4} ><Logo /></Grid>
                        <Grid item xs={5} >
                            <Typography variant="h6" sx={{ paddingLeft: 3, color: 'text.secondary' }}>
                                Tracciabilità dei beni culturali e opere
                            </Typography>
                        </Grid>

                    </Grid>

                    <Box sx={{
                        backgroundColor: 'primary.dark',
                        height: 35,
                        width: '100%',
                    }} />

                    <div style={{ paddingLeft: '8%', paddingRight: '8%' }} class="panel-center col-xs-12">
                        <article id="post-3" class="post-3 page type-page status-publish">

                            <div class="entry-content post-content">





                                <p>La società&nbsp;<strong>Solidity 2 s.r.l.&nbsp;</strong>con sede legale in San Benedetto del Tronto (AP), Via Oberdan n. 31, e sede operativa in San Benedetto del Tronto (AP), Via Pontida n. 6, Codice fiscale e Partita IVA 02454210440 quale titolare del trattamento dati e gestore della piattaforma RegistroArte.it</p>



                                <p></p>



                                <p></p>



                                <p><strong>INFORMA</strong></p>



                                <p>ai sensi del Regolamento U.E. G.D.P.R. 2016/679 (di seguito definito anche GDPR) ed al D.Lgs. D. Lgs. 30/06/2003 n. 196, come modificato dal D. Lgs. 10/08/2018 n. 101 e s.m.i., che i dati personali conferiti dagli interessati tramite i vari canali di raccolta, direttamente o indirettamente gestiti dal Titolare del trattamento, ovvero acquisiti presso terzi nel rispetto delle condizioni di legge, saranno trattati in modo lecito e secondo correttezza, nel rispetto dei principi sanciti dall’ordinamento comunitario ed italiano.</p>



                                <p>A tale proposito si forniscono le seguenti informazioni:</p>



                                <p><strong>Titolare del trattamento&nbsp;</strong>(di seguito definito anche Titolare)Solidity 2 s.r.l. con sede legale in San Benedetto del Tronto (AP), Via Oberdan n. 31, e sede operativa in San Benedetto del Tronto (AP), Via Pontida n. 6, Codice fiscale e Partita IVA 02454210440, email:&nbsp;info@solidity2.it, pec:&nbsp;solidity2@pec.it.</p>



                                <p><strong>Responsabile della protezione dati (D.P.O.)</strong></p>



                                <p>Gianni Corradetti, email:&nbsp;gianni@medinggroup.it, pec:&nbsp;dpo.corradetti@pec.it.</p>



                                <p><strong>Finalità di trattamento dei dati e base giuridica</strong></p>



                                <p>Il trattamento dati personali, raccolti ed archiviati dal Titolare, direttamente o indirettamente gestiti, anche tramite questo sito web o conferiti al Titolare a vario titolo dagli interessati, è effettuato per le seguenti finalità: vetrina sulle attività del Titolare (base giuridica: legittimo interesse del Titolare, art. 6 comma 1 lettera f del GDPR); risposta alle domande dei visitatori del sito, inviate attraverso l’apposito modulo (base giuridica: misure precontrattuali, art. 6 comma 1 lettera b del GDPR); raccolta di dati necessari al corretto funzionamento del sito web ed analisi statistica aggregata delle visite del sito web (base giuridica: legittimo interesse del Titolare, art. 6 comma 1 lettera f del GDPR).</p>



                                <p><strong>Destinatari dei dati e trasferimento dati all’estero</strong></p>



                                <p>Nei limiti pertinenti alle finalità di trattamento indicate, i dati potranno essere comunicati a partner e consulenti, nominati Responsabili dal Titolare del Trattamento. Inoltre i dati dovranno essere ceduti a terzi nell’adempimento di obblighi derivanti da leggi o regolamenti (Istituzioni, Forze dell’Ordine, Autorità Giudiziaria, ecc.). I dati raccolti non saranno oggetto di diffusione, né trasferiti al di fuori dell’UE.</p>



                                <p><strong>Periodo di conservazione</strong></p>



                                <p>I dati raccolti, fatti salvi gli obblighi stabiliti dalla legge, verranno conservati per un arco di tempo non superiore al conseguimento delle finalità per le quali sono trattati. In ogni caso i dati relativi alle richieste di informazioni da parte dei visitatori del sito saranno conservati per un periodo massimo di tre anni. I dati saranno comunque periodicamente verificati, anche con procedure automatiche, al fine di garantirne l’aggiornamento e la effettiva rispondenza alle finalità del trattamento.</p>



                                <p><strong>Natura del conferimento dei dati</strong></p>



                                <p>Il conferimento dei dati personali richiesti per rispondere alle domande dei visitatori del sito è obbligatorio. In mancanza di tali dati non sarà possibile per il Titolare rispondere a tali domande.</p>



                                <p>L’utilizzo dei cookie tecnici è obbligatorio per il corretto funzionamento del sito, mentre gli altri eventuali tipi di cookie sono disattivati di default. Tali cookie sono comunque attivabili/disattivabili tramite apposita funzione presente sulla pagina web delle preferenze cookie.</p>



                                <p><strong>Modalità di trattamento dei dati</strong></p>



                                <p>I dati personali forniti, formeranno oggetto di operazioni di trattamento nel rispetto della normativa sopracitata e degli obblighi di riservatezza cui è ispirata l’attività del Titolare. I dati verranno sottoposti a trattamento sia cartaceo che elettronico e/o automatizzato, nel rispetto di adeguate misure tecniche ed organizzative di sicurezza previste dal GDPR.</p>



                                <p><strong>Dati di navigazione</strong></p>



                                <p>Il sistema IT ed i software utilizzati per il portale web aziendale acquisiscono, durante il loro normale funzionamento, alcuni dati personali la cui trasmissione è implicita nell’uso dei prodotti di comunicazione di Internet.Questa categoria di dati riguarda gli indirizzi IP (non conservati dal Titolare ed anonimizzati durante l’utilizzo dei cookie statistici) e nomi a dominio del computer utilizzato dall’utente per collegarsi al sito, gli indirizzi URL (Uniform Resource Locator) della risorsa richiesta, il tempo della richiesta, il metodo usato per inviare la richiesta al server, la dimensione del file ricevuto, il codice numerico usato per indicare lo stato della risposta data dal server (eseguito o errore, ecc.) ed altri parametri relativi al sistema operativo e al computer dell’utente.</p>



                                <p>Questi dati sono usati soltanto per realizzare statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento. Normalmente sono cancellati subito dopo l’elaborazione. Possono essere utilizzati e forniti alle forze dell’ordine e alla magistratura per accertare responsabilità in caso di danneggiamento del sito o di illeciti perpetrati tramite la rete.</p>



                                <p><strong>Cookie</strong></p>



                                <p>Nel sito web aziendale&nbsp;www.solidity2.it&nbsp;sono utilizzati cookie di tipo tecnico, necessari per il normale funzionamento del sistema utilizzato per la sua gestione, i quali non vengono utilizzati per scopi ulteriori e sono normalmente installati direttamente dal titolare del sito web.Tali cookie sono utilizzati, come da normativa vigente, con la base giuridica del legittimo interesse del Titolare e senza la necessità del consenso da parte dei visitatori del sito. Non è possibile disabilitarli utilizzando l’apposita pagina di gestione cookie.</p>



                                <p><strong>Cookies statistici</strong></p>



                                <p>Tali cookie hanno la finalità di analizzare e migliorare l’esperienza d’uso dei siti/servizi digitali mediante la rilevazione qualitativa e quantitativa dei dati di fruizione. Nell’utilizzo di questi cookie è attiva la modalità di rendere anonimo l’indirizzo IP dell’utente e non è prevista alcuna attività di profilazione. Sono quindi, come da linee guida del Garante per la protezione dei dati personali, equiparati ai cookie tecnici e quindi utilizzati con la stessa base giuridica del legittimo interesse del Titolare e senza la necessità del consenso da parte dei visitatori del sito. Possono essere disabilitati utilizzando l’apposita pagina di gestione cookie.</p>



                                <p><strong>Cookies di profilazione</strong></p>



                                <p>Non viene effettuata attività di profilazione degli utenti o visitatori del sito web.</p>



                                <p><strong>Comunicazione e diffusione</strong></p>



                                <p>I dati trattati sono esclusivamente di natura comune e non sono destinati alla diffusione. Il Titolare non richiede e non ha interesse a rilevare e trattare dati classificati dal Regolamento come “particolari” (sensibili, giudiziari, genetici, biometrici, ecc.) fatti salvi gli obblighi di legge. I dati dovranno essere ceduti a terzi nell’adempimento di obblighi derivanti da leggi o regolamenti</p>



                                <p></p>



                                <p>(Istituzioni, Forze dell’Ordine, Autorità Giudiziaria, ecc.) ovvero per attività direttamente o indirettamente connesse al rapporto instaurato. A titolo esemplificativo e non esaustivo si citano:</p>



                                <ul>
                                    <li>Soggetti che hanno necessità di accedere ai dati dell’interessato per finalità riguardanti il rapportocon il Titolare (Istituti di credito, Intermediari finanziari, Istituti di moneta elettronica e gestionedei pagamenti, Società di recupero crediti, Società di verifica della clientela, Vettori, ecc.);</li>



                                    <li>Imprese che erogheranno eventuali servizi tecnici;</li>



                                    <li>Consulenti, collaboratori, società di servizi, nei limiti necessari per espletare l’incarico conferitodal Titolare;</li>



                                    <li>Società controllate e/o collegate che possono accedere ai dati, nei limiti strettamente necessari persvolgere compiti affidati dal Titolare.I dati potranno essere comunicati a soggetti operanti nell’ambito dell’Unione Europea, o in paesi che garantiscano lo stesso livello di protezione previsto dal Regolamento Europeo 679/2016. Potranno, inoltre, essere eventualmente comunicati a soggetti operanti in paesi non UE ove espressamente consentito dall’interessato. In ogni caso, il trattamento dei dati operato nei vari paesi sarà adeguato alle norme più restrittive, al fine di assicurare comunque il massimo livello di tutela dei dati dell’interessato. Potranno essere ceduti a terzi, anche a titolo oneroso, se l’interessato avrà rilasciato espresso consenso, per finalità direttamente o indirettamente connesse all’attività del Titolare.</li>
                                </ul>



                                <p></p>



                                <p><strong>Diritti dell’interessato</strong></p>



                                <p><strong><a href="https://www.solidity2.it/wp-content/uploads/2022/09/Modulo.richieste.interessati.pdf" data-type="URL" data-id="https://www.solidity2.it/wp-content/uploads/2022/09/Modulo.richieste.interessati.pdf" target="_blank" rel="noreferrer noopener">Clicca qui </a></strong>per scaricare il modulo per l’esercizio dei tuoi diritti privacy ai sensi degli artt. 15 e seguenti del GDPR</p>



                                <p>Agli interessati sono riconosciuti i diritti di cui all’articolo 13 del Regolamento EU/2016/679 e, in particolare, la facoltà di revocare in qualsiasi momento il consenso al trattamento dei dati, chiederne la rettifica, l’aggiornamento, la trasformazione in forma anonima, limitarne anche parzialmente l’utilizzo, chiederne la portabilità e l’eventuale cancellazione. I diritti sono esercitabili nei limiti in cui il trattamento non sia obbligatorio per disposizioni di legge o regolamento. Le istanze relative all’esercizio dei diritti dell’interessato possono essere indirizzate al Titolare del trattamento o al Responsabile della protezione dei dati agli indirizzi email sopra specificati. L’interessato, qualora non sia soddisfatto del riscontro fornito alle sue richieste dal Titolare del trattamento o dal Responsabile della protezione dei dati, può proporre reclamo all’Autorità Garante ai recapiti indicati nel sito&nbsp;www.garanteprivacy.it.</p>



                                <p></p>



                                <p>(ultimo aggiornamento di questo documento: settembre 2023)</p>

                            </div>






                        </article>

                    </div>

                    <Box
                        display="flex"
                        padding={1}
                        justifyContent="flex-start"
                        alignItems="flex-start" sx={{
                            backgroundColor: 'primary.dark',
                        }} >

                        <Stack flex={1} spacing={2} padding={4}>

                            <img style={{ width: 240 }} src="/assets/icons/logo_osservatorio_registro_arte.png" alt="" />
                            <Typography variant="subtitle1" sx={{ color: 'common.white' }}>
                                OSSERVATORIO ITALIA ANTIRICICLAGGIO PER L'ARTE - C.F. 96515970588 - 00137 Roma (RM)
                            </Typography>
                            <Divider sx={{ borderColor: 'common.white' }} />
                            <Typography variant="body2" sx={{ color: 'common.white' }}>
                                Registro Arte è una piattaforma, di proprietà intellettuale di Solidity2 Srl, in licenza all’Osservatorio Italia Antiriciclaggio per l'Arte il quale ha come obiettivo la tracciabilità dei beni culturali ed opere d’arte per finalità di antiriciclaggio, anticontraffazione e diritti di seguito.
                            </Typography>

                            <Typography sx={{ color: 'common.white' }}>
                                <a style={{ color: 'white' }} target='blank' href='https://registroarte.it/assets/Condizioni_Generali.pdf' >Termini e condizioni</a>
                            </Typography>
                            <Divider sx={{ borderColor: 'common.white' }} />
                            <Stack spacing={4} direction="row">
                                <img style={{ width: 169, height: 19 }} src="/assets/icons/logo_solidity_registroarte.png" alt="" />
                                <Typography variant="subtitle1" sx={{ color: 'common.white' }}>
                                    © Copyright {new Date().getFullYear()} - Solidity2 srl - Startup Innovativa - P.IVA: 02454210440 – REA: AP-273622

                                </Typography>
                            </Stack>
                            <Typography sx={{ color: 'common.white' }}>
                                Email  :<a style={{ color: 'white' }} href='mailto:info@solidity2.it' >info@solidity2.it</a>
                            </Typography>
                        </Stack>
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center" sx={{
                            backgroundColor: 'primary.darker',
                        }} >

                    </Box>

                </Box>
            </StyledRoot>
        </>
    )
}