import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, FormControlLabel, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify'
// API
import { logout, resetFinish } from 'src/api/auth';

// ----------------------------------------------------------------------

export default function ResetForm() {
  const navigate = useNavigate();

  const queryParameters = new URLSearchParams(window.location.search)
  const key = queryParameters.get("key")

  const [showPassword, setShowPassword] = useState(false);
  const [showConfermaPassword, setShowConfermaPassword] = useState(false);

  const [err, setErr] = useState('');

  const [accettaCondizioni, setAccettaCondizioni] = useState(false);

  const [password, setPassword] = useState('');
  const [confermaPassword, setConfermaPassword] = useState('');

  const handleAccettaCondizioni = (event) => {
    setAccettaCondizioni(event.target.checked)
  }

  const handleReset = (event) => {
    event.preventDefault();
    if (accettaCondizioni) {
      if (password === confermaPassword) {
        logout();
        resetFinish(key, password)
          .then(() => {
            navigate('/login', { replace: true });
          })
          .catch((error) => {
            setErr("Errore: Ricontrollare la Password (min. 8 caratteri)");
          })
      } else {
        setErr("Le Password non corrispondono.");
      }
    } else {
      setErr("Accettare le condizioni generali.");
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfermaPasswordChange = (event) => {
    setConfermaPassword(event.target.value);
  }


  return (
    <>
      <form onSubmit={handleReset}>
        <Stack spacing={3}>
          {err && err.length > 0 ? (
            <span style={{ color: "#FF4842" }} >{err}</span>
          ) : (
            <></>
          )}

          <TextField

            name="password1"
            label="Nuova Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handlePasswordChange} />

          <TextField

            name="password2"
            label="Conferma Password"
            type={showConfermaPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowConfermaPassword(!showConfermaPassword)} edge="end">
                    <Iconify icon={showConfermaPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handleConfermaPasswordChange} />

          <FormControlLabel control={<Checkbox checked={accettaCondizioni} onChange={handleAccettaCondizioni} />} label={<a target='blank' href='https://registroarte.it/assets/Condizioni_Generali.pdf'>Accetta le Condizioni Generali</a>} />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" >
            Cambia Password
          </LoadingButton>
        </Stack>

      </form>


    </>
  );
}

