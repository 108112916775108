import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  FormControl,
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControlLabel
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { createAuthor, editAuthor, listAuthors, searchAuthor } from 'src/api/art';
// router
import { useNavigate } from 'react-router-dom';

// logged user
import { useUser } from 'src/layouts/dashboard/DashboardLayout';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID Autore', alignRight: false },
  { id: 'nome', label: 'Nome', alignRight: false },
  { id: 'cognome', label: 'Cognome', alignRight: false },
  { id: 'bio', label: 'Biografia', alignRight: false },
  { id: 'scuola', label: 'Scuola/ Bottega', alignRight: false },
  { id: 'birthday', label: 'Anno di nascita', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function AuthorPage() {
  const [errCrea, setErrCrea] = useState(false);
  const [openCrea, setOpenCrea] = useState(null);

  const [openModifica, setOpenModifica] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('id');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(parseInt(sessionStorage.getItem('rowsPerPage')) || 5);

  const [AssetList, setAssetList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);

  const [sconosciuto, setSconosciuto] = useState(false);

  // ====== CAMPI CREA AUTORE =========

  const [nome, setNome] = useState('');
  const [cognome, setCognome] = useState('');
  const [bio, setBio] = useState('');
  const [scuola, setScuola] = useState('');
  const [birthDay, setBirthDay] = useState('');

  const handleNomeChange = (event) => {
    setNome(event.target.value);
  }
  const handleCognomeChange = (event) => {
    setCognome(event.target.value);
  }
  const handleScuolaChange = (event) => {
    setScuola(event.target.value);
  }
  const handleBioChange = (event) => {
    setBio(event.target.value);
  }
  const handleSconosciuto = (event) => {
    setSconosciuto(event.target.checked);
    if (event.target.checked) {
      setNome('Sconosciuto')
    }
  }
  const handleBirthDayChange = (event) => {
    setBirthDay(event.target.value);
  }

  // ====== CAMPI MODIFCA AUTORE =========

  const [nomeEdit, setNomeEdit] = useState('');
  const [cognomeEdit, setCognomeEdit] = useState('');
  const [bioEdit, setBioEdit] = useState('');
  const [scuolaEdit, setScuolaEdit] = useState('');
  const [birthDayEdit, setBirthDayEdit] = useState('');

  const handleNomeChangeEdit = (event) => {
    setNomeEdit(event.target.value);
  }
  const handleCognomeChangeEdit = (event) => {
    setCognomeEdit(event.target.value);
  }
  const handleBioChangeEdit = (event) => {
    setBioEdit(event.target.value);
  }
  const handleScuolaChangeEdit = (event) => {
    setScuolaEdit(event.target.value);
  }
  const handleBirthDayChangeEdit = (event) => {
    setBirthDayEdit(event.target.value);
  }
  // ================================ 

  const navigate = useNavigate();

  const handleOpenMenuCrea = (event) => {
    setOpenCrea(event.currentTarget);
  }

  const handleCloseMenuCrea = () => {
    setOpenCrea(null);
  }
  const handleOKCloseMenuCrea = async () => {
    await createAuthor(nome, cognome, bio, scuola, birthDay)
      .then(
        (result) => {
          navigate(0);
        },
        (error) => {
          setErrCrea(true)
        });
    //setOpenCrea(null);
  }

  // ============ Menu Modifica ===============

  const handleOpenMenuModifica = (event, authorId) => {
    setSelectedItem(authorId);
    const item = filteredAssets.find(a => a.id == authorId);
    setNomeEdit(item.nome);
    setCognomeEdit(item.cognome);
    setBioEdit(item.bio);
    setScuolaEdit(item.scuola);
    setBirthDayEdit(item.birthday);
    setOpenModifica(event.currentTarget);
  }

  const handleCloseMenuModifica = () => {
    setOpenModifica(null);
  }
  const handleOKCloseMenuModifica = async () => {
    await editAuthor(selectedItem, nomeEdit, cognomeEdit, bioEdit, scuolaEdit, birthDayEdit)
      .then().finally(() => {
        navigate(0);
      });
    setOpenModifica(null);
  }

  const handleRequestSort = (event, property) => {
    //
  };

  const handleSelectAllClick = (event) => {
    //
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    sessionStorage.setItem('rowsPerPage', event.target.value);
  };

  const handleFilterByName = async (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - AssetList.length) : 0;

  const filteredAssets = AssetList;

  const isNotFound = !filteredAssets.length && !!filterName;

  const { user } = useUser();

  useEffect(() => {
    const load = async () => {
      var _list = await searchAuthor(page, rowsPerPage, order, orderBy, filterName);
      setTotal(_list.headers["x-total-count"]);
      if (_list) {
        setAssetList(_list.data.filter(a => a !== null));
        setLoading(false);
      }
    }
    load();
  }, [filterName]);

  useEffect(() => {
    const load = async () => {
      var _list = [];
      if (filterName.length > 0) {
        _list = await searchAuthor(page, rowsPerPage, order, orderBy, filterName, Number(filterName));
      } else {
        _list = await listAuthors(page, rowsPerPage, order, orderBy);
      }
      setTotal(_list.headers["x-total-count"]);
      if (_list) {
        setAssetList(_list.data.filter(a => a !== null));
        setLoading(false);
      }
    }
    load();
  }, [page, rowsPerPage, order, orderBy]);

  return (
    <>
      <Helmet>
        <title> Autori | Registro Arte </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Lista Autori/Autrici
          </Typography>
          <Button onClick={handleOpenMenuCrea} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Aggiungi Autore/Autrice
          </Button>
        </Stack>

        {loading ? (
          <img src="/assets/icons/Pulse-1s-200px.svg" />
        ) : (
          <Card>
            <UserListToolbar placeholder="Cerca (Nome, Cognome, Biografia, Scuola, Anno)" showSearchBar={true} numSelected={0} filterName={filterName} onFilterName={handleFilterByName} />

            <Scrollbar>
              <TableContainer>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={AssetList.length}
                    numSelected={0}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {AssetList.map((row) => {
                      const { id, nome, cognome, bio, scuola, birthday, createdBy } = row;
                      const selectedAsset = selected.indexOf(id) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedAsset}>

                          <TableCell align="left">{id}</TableCell>

                          <TableCell align="left">{nome}</TableCell>

                          <TableCell align="left">{cognome}</TableCell>

                          <TableCell align="left">{bio}</TableCell>

                          <TableCell align="left">{scuola}</TableCell>

                          <TableCell align="left">{birthday}</TableCell>

                          <TableCell align="left">

                            {!loading && (user.id.toString() == createdBy) ?

                              <Button onClick={(event) => { handleOpenMenuModifica(event, id) }} title='Modifica Autore' >
                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />

                              </Button>
                              :

                              <Button style={{ opacity: 0.5 }} disabled >
                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />

                              </Button>

                            }

                          </TableCell>

                        </TableRow>
                      );
                    })}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Non trovato
                            </Typography>

                            <Typography variant="body2">
                              Nessun risultato trovato per &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Prova a ricontrollare eventuali errori.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>

      {/* CREAZIONE AUTORE  */}
      <div>
        <Dialog disableEscapeKeyDown open={openCrea} onClose={handleCloseMenuCrea}>
          <DialogTitle>Inserisci Autore/Autrice</DialogTitle>
          {errCrea ?
            <span style={{ color: "#FF4842" }} >Errore : Inserisci correttamente i campi.</span>
            : <></>
          }
          <DialogContent>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <FormControlLabel control={<Checkbox checked={sconosciuto} onChange={handleSconosciuto} />} label='Sconosciuto/a' />
                {sconosciuto ?
                  <>
                    <TextField sx={{ bgcolor: 'grey' }}
                      disabled label="Nome"
                    />
                    <TextField sx={{ bgcolor: 'grey' }}
                      disabled label="Cognome"
                    />
                  </>
                  :
                  <>
                    <TextField
                      name="nome" label="Nome"
                      onChange={handleNomeChange} />
                    <TextField
                      sx={{ mt: 2 }}
                      name="cognome" label="Cognome"
                      onChange={handleCognomeChange} />
                  </>
                }
                <TextField
                  sx={{ mt: 2 }}
                  name="scuola" label="Scuola/ Bottega"
                  onChange={handleScuolaChange} />
                <TextField
                  sx={{ mt: 2 }}
                  name="bio" label="Biografia"
                  onChange={handleBioChange} />
                <TextField
                  sx={{ mt: 2 }}
                  name="birthday" label="Anno di nascita"
                  onChange={handleBirthDayChange} />

              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMenuCrea}>Annulla</Button>
            <Button onClick={handleOKCloseMenuCrea}>Crea</Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* MODIFICA AUTORE  */}
      <div>
        <Dialog disableEscapeKeyDown open={openModifica} onClose={handleCloseMenuModifica}>
          <DialogTitle>Modifica Autore/Autrice</DialogTitle>
          <DialogContent>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <TextField
                  name="nome" label="Nome" value={nomeEdit}
                  onChange={handleNomeChangeEdit} />
                <TextField
                  sx={{ mt: 2 }}
                  name="cognome" label="Cognome" value={cognomeEdit}
                  onChange={handleCognomeChangeEdit} />
                <TextField
                  sx={{ mt: 2 }}
                  name="bio" label="Biografia" value={bioEdit}
                  onChange={handleBioChangeEdit} />
                <TextField
                  sx={{ mt: 2 }}
                  name="scuola" label="Scuola/ Bottega" value={scuolaEdit}
                  onChange={handleScuolaChangeEdit} />
                <TextField
                  sx={{ mt: 2 }}
                  name="birthday" label="Anno di nascita" value={birthDayEdit}
                  onChange={handleBirthDayChangeEdit} />

              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMenuModifica}>Annulla</Button>
            <Button onClick={handleOKCloseMenuModifica}>Modifica</Button>
          </DialogActions>
        </Dialog>
      </div>

    </>
  );
}
