import PropTypes from 'prop-types';
// @mui
import { Card, Box } from '@mui/material';

// ----------------------------------------------------------------------

AppImagePrevie.propTypes = {
  image: PropTypes.string,
};

export default function AppImagePrevie({ image, label, ...other }) {
  
  return (
    <Card {...other}>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <img src={image} alt={label} />
      </Box>
    </Card>
  );
}
