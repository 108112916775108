import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import AuthorPage from './pages/AuthorPage';
import UserPage from './pages/UserPage';
import UserPageToverify from './pages/UserPageToverify';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import ArtPage from './pages/ArtPage';
import HomePage from './pages/HomePage';
import ResetPage from './pages/ResetPage';
import CertificatePage from './pages/CertificatePage';
import PrivacyPage from './pages/PrivacyPage';
import HelpPage from './pages/HelpPage';
import SettingsPage from './pages/SettingsPage';
import HistoryPage from './pages/HistoryPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/home',
      element: <HomePage />
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPage />
    },
    {
      path: '/certificate',
      element: <CertificatePage />
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'utenti', element: <UserPage /> },
        { path: 'utenti2', element: <UserPageToverify /> },
        { path: 'opere', element: <ArtPage /> },
        { path: 'autori', element: <AuthorPage /> },
        { path: 'assistenza', element: <HelpPage /> },
        {
          path: 'settings',
          element: <SettingsPage />,
        },
        {
          path: 'storico',
          element: <HistoryPage />
        },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <SignupPage />,
    },
    {
      path: 'reset',
      element: <ResetPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
