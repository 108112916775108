import { TextField } from "@mui/material";
import { useRef, useEffect } from "react";

const AutoComplete = ({ name, label, handler, form, disabled }) => {
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        componentRestrictions: { country: "it" },
        fields: ["formatted_address", "name"],
    };
    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            options
        );
        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace()
            form[name] = place.name;
            handler(form);
        });
    }, [form]);
    return (

        <TextField disabled={disabled} value={form[name]} inputRef={inputRef} 
            name={name} label={label} />

    );
};
export default AutoComplete;
