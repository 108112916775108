import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// api
import { getUser } from 'src/api/auth';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const load = async () => {
      const _user = await getUser();
      if (_user) {
        setUser(_user);
        setLoading(false);
      }
    }
    load();
  }, []);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <a href='/dashboard/app'>
          <img
            style={{
              filter: 'brightness(0) invert(1)'
            }}
            src='/assets/icons/white_logo.png' alt='Logo' />
        </a>
      </Box>

      <Box sx={{ mt: 2,mb: 1, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={account.photoURL} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              {user.person != null ?
                <Typography variant="subtitle2" sx={{ color: 'common.white' }}>
                  {user.person.id} , {user.person.firstName} {user.person.lastName}
                </Typography> : <></>
              }

            </Box>
          </StyledAccount>
        </Link>
      </Box>

      { !loading &&  !user.authorities.filter(a => a.includes('ADMIN') || a.includes('POLICE') || a.includes('PUBLIC')).length > 0  ?
        <NavSection data={navConfig.filter(nav => !nav.title.includes('Utenti'))} />
        :
        <NavSection data={navConfig} />
      }
      <Box sx={{ flexGrow: 1 }} />

    </Scrollbar>
  );

  return (
    <>
      {loading ? (
        <img src = "/assets/icons/Pulse-1s-200px.svg" />
      ) : (

        <Box
          component="nav"
          sx={{
            flexShrink: { lg: 0 },
            width: { lg: NAV_WIDTH },
          }}
        >
          {isDesktop ? (
            <Drawer
              open
              variant="permanent"
              PaperProps={{
                sx: {
                  width: NAV_WIDTH,
                  bgcolor: 'primary.main',
                  borderRightStyle: 'dashed',
                },
              }}
            >
              {renderContent}
            </Drawer>
          ) : (
            <Drawer
              open={openNav}
              onClose={onCloseNav}
              ModalProps={{
                keepMounted: true,
              }}
              PaperProps={{
                sx: {
                  width: NAV_WIDTH,
                  bgcolor: 'primary.main',
                  borderRightStyle: 'inherit',
                },
              }}
            >
              {renderContent}
            </Drawer>
          )}
        </Box>
      )}
    </>
  );
}
