import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Card, Box, Container, Typography } from '@mui/material';
// user state
import { useUser } from '../layouts/dashboard/DashboardLayout';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCardSummary,
  AppCurrentSubject,
  AppConversionRates,
  AppImagePreview,
} from '../sections/@dashboard/app';

import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  const { user } = useUser();

  const mdUp = useResponsive('up', 'md');

  const summaryTest =
    <>
      <Typography>Registro Arte agisce nel rispetto della privacy e GDPR. Pertanto, in ogni momento, i dati personali e sensibili su richiesta dell’utente, possono essere cancellati dal servizio.</Typography>
      <Typography>Istruzioni per l'Utilizzo del Registro d'Arte</Typography>
      <Typography sx={{ fontWeight: 'bold' }}>Autori/Autrici:</Typography>
      <Typography>Il registro consente di registrare gli Autori/Autrici e le relative informazioni</Typography>
      <Typography sx={{ fontWeight: 'bold' }}>Opere d'Arte:</Typography>
      <Typography>Il registro consente di:</Typography>
      <Typography>- registrare Opere d'Arte e le relative informazioni </Typography>
      <Typography>- ricerca delle Opere d'Arte tramite il form di ricerca o l'elenco delle opere d'arte per trovare l'opera specifica che desiderate verificare. titolo, artista o numero di identificazione.</Typography>
      <Typography>- visualizzazione delle Informazioni dell'opera d'arte registrata e del Passaporto digitale</Typography>
      <Typography>- visualizzare lo storico delle transazioni dell'opera d'arte, che mostra chi è stato il proprietario precedente e quando è stata trasferita. Questo può aiutare a tracciare la provenienza dell'opera.</Typography>
      <Typography sx={{ fontWeight: 'bold' }}>Sicurezza:</Typography>
      <Typography>Mantenete sempre al sicuro le password del vostro utente.</Typography>
      <Typography sx={{ fontWeight: 'bold' }}>Consulenza Legale:</Typography>
      <Typography>Per transazioni di valore significativo o in caso di dubbi sull'autenticità di un'opera d'arte, consultare un esperto legale specializzato in diritto d'autore e diritti d'immagine.</Typography>
      <Typography sx={{ fontWeight: 'bold' }}>Assistenza Clienti:</Typography>
      <Typography>In caso di problemi, domande o necessità di assistenza, contattate l'assistenza clienti del servizio di registro d'arte. Saremo lieti di aiutarvi con qualsiasi problema.</Typography>
      <Typography>Buon utilizzo del Registro Arte!</Typography>
    </>

  return (
    <>
      <Helmet>
        <title> Dashboard | Registro Arte </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>

          {new Date().toLocaleDateString('it-IT', { weekday: "long", year: "numeric", month: "short", day: "numeric" })}
        </Typography>

        <Grid container spacing={3}>

          <Grid item xs={12}>
            <AppCardSummary title={`Salve, ${user.person.firstName} ${user.person.lastName} ( ID utente ${user.person.id}) 👋`} body={<></>} icon={'ant-design:android-filled'} />
          </Grid>

          <Grid item xs={12}>
            {mdUp ?

              <Card>
                <Box sx={{ marginLeft: '25%', p: 3, pb: 1 }} dir="ltr">
                  <img src="/assets/illustrations/imgdashboard.png" alt="" />
                </Box>
              </Card>

              :
              <AppImagePreview image="/assets/illustrations/imgdashboard.png" label="..." />
            }
          </Grid>

          <Grid item xs={12}>
            <AppCardSummary title="" body={summaryTest} icon={'ant-design:android-filled'} />
          </Grid>

        </Grid>
      </Container>

    </>
  );
}
