import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, Link, IconButton, TableCell, Checkbox, InputAdornment, TextField, FormControlLabel } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
// api
import { logout, signupBasic } from '../../../api/auth';

// ----------------------------------------------------------------------

export default function SignupForm() {

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [accettaCondizioni, setAccettaCondizioni] = useState(false);
  const [accettaPrivacy, setAccettaPrivacy] = useState(false);

  // registration form states
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDay, setBirthDay] = useState(null);
  const [fiscalCode, setFiscalCode] = useState(null);

  const [err, setErr] = useState('');
  const [confirm, setConfirm] = useState('');

  const handleAccettaCondizioni = (event) => {
    setAccettaCondizioni(event.target.checked)
  }

  const handleAccettaPrivacy = (event) => {
    setAccettaPrivacy(event.target.checked)
  }

  const handleClick = (event) => {
    event.preventDefault();
    [email, password, firstName, lastName, birthDay, fiscalCode].forEach((value) => {
      if (value === '') {
        setErr("Inserire i campi sono obbligatori");
      }
    })

    if (accettaCondizioni && accettaPrivacy) {

      if (password === confirmPassword) {

        logout();
        if (fiscalCode != null && !fiscalCode.match(/^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/)) {
          setErr('Errore: Codice Fiscale errato.');
        } else {
          signupBasic("ROLE_USER", email, password, firstName, lastName, birthDay, fiscalCode)
            .then((response) => {
              setConfirm('Le è stata inviata una mail con le istruzioni all\'indirizzo specificato.');
              setErr('');
            }).catch((error) => {
              if (error.response.data.fieldErrors != null) {
                setErr('La password deve contenere almeno 8 caratteri.');
              } else {
                setErr(error.response.data.message);
              }
            });
        }
      } else {
        setErr("Le password inserite non corrispondono.");
      }

    } else if (!accettaCondizioni) {
      setErr("Accettare le condizioni generali.");
    } else if (!accettaPrivacy) {
      setErr("Accettare l'informativa sulla privacy.");
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePwdChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePwdConfirmChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleBirthDayChange = (value) => {
    var date = new Date(value);

    // Get year, month, and day part from the date
    var year = date.toLocaleString("default", { year: "numeric" });
    var month = date.toLocaleString("default", { month: "2-digit" });
    var day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    var formattedDate = year + "-" + month + "-" + day;
    setBirthDay(formattedDate);
  };

  const handleFiscalCodeChange = (event) => {
    setFiscalCode(event.target.value);
  };

  return (
    <>
      <form onSubmit={handleClick}>
        <Stack spacing={3}>
          {err.length > 0 ? (
            <span style={{ color: "#FF4842" }} >{err}</span>
          ) : (
            <></>
          )}
          {confirm != '' ? (
            <span>{confirm}</span>
          ) : (
            <></>
          )}
          <TextField
            name="email" label="Email"
            onChange={handleEmailChange}
          />

          <TextField
            name="firstName" label="Nome"
            onChange={handleFirstNameChange}
          />

          <TextField
            name="lastName" label="Cognome"
            onChange={handleLastNameChange}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <DatePicker
              format='DD/MM/YYYY'
              name="birthDay" label="Data di nascita"
              onChange={handleBirthDayChange}
            />
          </LocalizationProvider>

          <TextField
            name="fiscalCode" label="Codice fiscale"
            onChange={handleFiscalCodeChange}
          />

          <TextField
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handlePwdChange} />

          <TextField
            InputLabelProps={{
              style: { color: 'grey', },
            }}
            name="confermaPassword"
            label="Conferma Password"
            type={showPassword2 ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword2(!showPassword2)} edge="end">
                    <Iconify icon={showPassword2 ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={handlePwdConfirmChange}
          />

          <FormControlLabel control={<Checkbox checked={accettaCondizioni} onChange={handleAccettaCondizioni} />} label={<a target='blank' href='https://registroarte.it/assets/Condizioni_Generali.pdf'>Accetta le Condizioni Generali</a>} />
          <FormControlLabel control={<Checkbox checked={accettaPrivacy} onChange={handleAccettaPrivacy} />} label={<a target='blank' href='https://registroarte.it/privacy-policy'>Dichiara di aver letto l’informativa privacy</a>} />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" >
            Registrati
          </LoadingButton>
        </Stack>


        <a href='/login'>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <Link variant="subtitle2" underline="hover">
              Torna a Login
            </Link>
          </Stack>
        </a>

      </form>
    </>
  );
}
