import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import{  Box,Container, Typography, Divider, Stack, Button }from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// sections
import { LoginForm } from '../sections/auth/login';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Login | Registro Arte </title>
      </Helmet>

      <StyledRoot>

        {mdUp && (
          <StyledSection sx={{ padding: 3 }}>
            <Box
              component="img"
              src='/assets/icons/registroarte_logo_head.png'
              sx={{ display: 'flex', width: 460, height: 72, cursor: 'pointer' }}
            />
            <Typography variant="h3" sx={{
              px: 7, mt: 2, mb: 5,
              color: (theme) => theme.palette.primary.dark
            }}>
              Bentornato
            </Typography>
            <img src="/assets/illustrations/unnamed.png" alt="login" />
            <Stack flex={1} spacing={2} padding={4}>
              <img style={{maxWidth:"450px"}} src="/assets/illustrations/retoalogo.jpeg" alt="retoa" />
              <Typography style={{color:"#004080"}} variant="subtitle1">
                            Progetto RETOA <br/>
                            Finanziato dall’Unione Europea – Next Generation EU <br/>
                            PNRR - Missione 1 Comp. 3 Mis. 3 Inv. 3.3 Sub. 3.3.2 <br/>
                            TRANSIZIONE DIGITALE ORGANISMI CULTURALI E CREATIVI <br/>
                            Progetto TOCC0000349 CUP C37J23000760008
              </Typography>
              <img style={{maxWidth:"450px"}} src="/assets/illustrations/NextGenerationEU_logo.jpeg" alt="" />
              <Typography style={{color:"#004080"}} variant="subtitle1">
                MATCHME srl via Cesare Battisti 48 LECCE (LE) CAP 73100 Piva 04875810758
              </Typography>
            </Stack>
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom sx={{
              color: (theme) => theme.palette.primary.dark
            }}>
              Accedi a Registro Arte
            </Typography>

            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
