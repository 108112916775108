import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';

import { useState, useEffect } from 'react';
// @mui
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  Card,
  Table,
  Stack,
  Paper,
  FormControl,
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Select
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { activate, changeRole, listAccounts, banAccount, deleteAccount, searchAccounts } from 'src/api/user';
import { signupBasic } from 'src/api/auth';

import { useNavigate } from 'react-router-dom';
import { useUser } from 'src/layouts/dashboard/DashboardLayout';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'fullName', label: 'Nome', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'role', label: 'Ruolo', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.person.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function roleToLabel(role) {
  if (role === "ROLE_SUPERADMIN") return "Super Admin"
  if (role === "ROLE_ADMIN") return "Admin"
  if (role === "ROLE_POLICE") return "Forze dell'ordine"
  if (role === "ROLE_PUBLIC") return "Istituzioni pubbliche"
  if (role === "ROLE_EXPERT") return "Esperto/a d'arte"
  if (role === "ROLE_ARTIST") return "Autore/Autrice"
  if (role === "ROLE_USER") return "Utente semplice"
}

export default function UserPage() {
  const [errCrea, setErrCrea] = useState('');
  const [open2, setOpen2] = useState(null);

  const [openCrea, setOpenCrea] = useState(null);
  const [openElimina, setOpenElimina] = useState(null);
  const [openSospendi, setOpenSospendi] = useState(null);
  const [openModifica, setOpenModifica] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('id');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(parseInt(sessionStorage.getItem('rowsPerPage')) || 5);

  const [userList, setUserList] = useState([]);
  const [userPendingList, setUserPendingList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);

  // ====== CAMPI CREA UTENTE =========

  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [cognome, setCognome] = useState('');
  const [ruolo, setRuolo] = useState('default');
  const [fiscalCode, setFiscalCode] = useState(null);
  const [birthDay, setBirthDay] = useState(null);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }
  const handleNomeChange = (event) => {
    setNome(event.target.value);
  }
  const handleCognomeChange = (event) => {
    setCognome(event.target.value);
  }
  const handleRuoloChange = (event) => {
    setRuolo(event.target.value);
  }
  const handleBirthDayChange = (value) => {
    var date = new Date(value);

    // Get year, month, and day part from the date
    var year = date.toLocaleString("default", { year: "numeric" });
    var month = date.toLocaleString("default", { month: "2-digit" });
    var day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    var formattedDate = year + "-" + month + "-" + day;
    setBirthDay(formattedDate);
  }
  const handleFiscalCodeChange = (event) => {
    setFiscalCode(event.target.value);
  }

  // ====== CAMPI MODIFCA UTENTE =========

  const [ruoloEdit, setRuoloEdit] = useState('');

  const handleRuoloChangeEdit = (event) => {
    setRuoloEdit(event.target.value);
  }

  // ============ Menu Elimina ===============

  const handleOpenMenuElimina = (event, authorId) => {
    setSelectedItem(authorId);
    setOpenElimina(event.currentTarget);
  }

  const handleCloseMenuElimina = () => {
    setOpenElimina(null);
  }
  const handleOKCloseMenuElimina = async () => {
    await deleteAccount(selectedItem)
      .then().finally(() => {
        navigate(0);
      });
    setOpenElimina(null);
  }

  // ============ Menu Sospendi ===============

  const handleOpenMenuSospendi = (event, authorId) => {
    setSelectedItem(authorId);
    setOpenSospendi(event.currentTarget);
  }

  const handleCloseMenuSospendi = () => {
    setOpenSospendi(null);
  }
  const handleOKCloseMenuSospendi = async () => {
    await banAccount(selectedItem)
      .then().finally(() => {
        navigate(0);
      });
    setOpenSospendi(null);
  }

  // ============ Menu Modifica ===============

  const handleOpenMenuModifica = (event, accountId) => {
    setSelectedItem(accountId);
    const item = userList.find(u => u.id == accountId);
    setRuoloEdit(item.authorities[0]);
    setOpenModifica(event.currentTarget);
  }

  const handleCloseMenuModifica = () => {
    setOpenModifica(null);
  }
  const handleOKCloseMenuModifica = async () => {
    const item = userList.find(u => u.id == selectedItem);
    await changeRole(selectedItem, item.authorities[0], ruoloEdit)
      .then().finally(() => {
        navigate(0);
      });
    setOpenModifica(null);
  }

  // ===========================================

  const navigate = useNavigate();
  const { user } = useUser();

  const handleOpenMenuCrea = (event) => {
    setOpenCrea(event.currentTarget);
  }

  const handleCloseMenuCrea = () => {
    setOpenCrea(null);
  }
  const handleOKCloseMenuCrea = async () => {
    let chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let pwordLength = 12;
    let password = '';

    const array = new Uint32Array(chars.length);
    window.crypto.getRandomValues(array);

    for (let i = 0; i < pwordLength; i++) {
      password += chars[array[i] % chars.length];
    }

    if (fiscalCode != null && !fiscalCode.match(/^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/)) {
      setErrCrea('Errore: Codice Fiscale errato.');
    } else {
      await signupBasic(ruolo, email, password, nome, cognome, birthDay, fiscalCode)
        .then(() => {
          navigate(0);
        })
        .catch(() => {
          setErrCrea('Errore: Inserisci correttamente i campi.')
        });
      //setOpenCrea(null);
    }

  }

  const handleAttiva = async (key) => {
    await activate(key).then().finally(() => { navigate(0) });
    setOpen2(false);
  }

  const handleRequestSort = (event, property) => {
    //
  };

  const handleSelectAllClick = (event) => {
    //
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    sessionStorage.setItem('rowsPerPage', event.target.value);
  };

  const handleFilterByName = async (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  // const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName);

  const filteredUsers = userList;

  const isNotFound = !filteredUsers.length && !!filterName;

  useEffect(() => {
    const load = async () => {
      var _list = await listAccounts(page, rowsPerPage, order, orderBy);
      if (_list) {
        _list.data.forEach(user => {
          user.ruolo = roleToLabel(user.authorities[0])
        });
        setUserList(_list.data.filter(u => u.person !== null && u.activated));
        setUserPendingList(_list.data.filter(u => u.person !== null && !u.activated));
        setTotal(Number(_list.headers["x-total-count"]));
        setLoading(false);
      }
    }
    load();
  }, [page, rowsPerPage, order, orderBy]);

  useEffect(() => {
    const load = async () => {
      var _list = [];
      if (filterName.length > 0) {
        _list = await searchAccounts(page, rowsPerPage, order, orderBy, filterName, Number(filterName));
      } else {
        _list = await listAccounts(page, rowsPerPage, order, orderBy);
      }
      setTotal(Number(_list.headers["x-total-count"]));
      if (_list) {
        setUserList(_list.data.filter(a => a !== null));
        setLoading(false);
      }
    }
    load();
  }, [filterName]);

  return (
    <>
      <div>
        <Helmet>
          <title> Utenti | Registro Arte </title>
        </Helmet>

        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Lista Utenti
            </Typography>
            {user != null && !user.authorities.filter(a => a.includes('POLICE') || a.includes('PUBLIC')).length > 0 ?
              <Button onClick={handleOpenMenuCrea} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                Aggiungi Utente
              </Button>
              : <></>}
          </Stack>

          {loading ? (
            <img src="/assets/icons/Pulse-1s-200px.svg" />
          ) : (
            <>
              <Card>
                <UserListToolbar placeholder="Cerca (ID, Nome, Cognome, Email)" showSearchBar={true} numSelected={0} filterName={filterName} onFilterName={handleFilterByName} />

                <Scrollbar>
                  <TableContainer>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={userList.length}
                        numSelected={0}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {userList.map((row) => {
                          const { id, ruolo, authorities, person } = row;
                          const selectedUser = selected.indexOf(id) !== -1;

                          return (
                            <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>

                              <TableCell align="left">{id}</TableCell>
                              {person != null ?
                                <>
                                  <TableCell align="left">{person.firstName} {person.lastName}</TableCell>

                                  <TableCell align="left">{person.email}</TableCell>
                                </>
                                : <></>}

                              <TableCell align="left">{roleToLabel(authorities[0])}</TableCell>

                              <TableCell align="left">

                                {user != null && !user.authorities.filter(a => a.includes('POLICE') || a.includes('PUBLIC')).length > 0 ?
                                  <Button onClick={(event) => { handleOpenMenuModifica(event, id) }} title='Modifica Ruolo Utente' >
                                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />

                                  </Button>
                                  :
                                  <Button style={{ opacity: 0.5 }} disabled >
                                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />

                                  </Button>
                                }

                                {user != null && !user.authorities.filter(a => a.includes('POLICE') || a.includes('PUBLIC')).length > 0 ?
                                  <Button onClick={(event) => { handleOpenMenuSospendi(event, id) }} title='Sospendi Utente' sx={{ color: 'error.main' }}>
                                    <Iconify icon={'fa-solid:ban'} sx={{ mr: 2 }} />

                                  </Button>
                                  :
                                  <Button style={{ opacity: 0.5 }} disabled >
                                    <Iconify icon={'fa-solid:ban'} sx={{ mr: 2 }} />

                                  </Button>
                                }

                                {user != null && !user.authorities.filter(a => a.includes('POLICE') || a.includes('PUBLIC')).length > 0 ?
                                  <Button onClick={(event) => { handleOpenMenuElimina(event, id) }} title='Elimina Utente' sx={{ color: 'error.main' }}>
                                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />

                                  </Button>
                                  :
                                  <Button style={{ opacity: 0.5 }} disabled >
                                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />

                                  </Button>
                                }

                              </TableCell>

                            </TableRow>
                          );
                        })}
                      </TableBody>

                      {isNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  Non trovato
                                </Typography>

                                <Typography variant="body2">
                                  Nessun risultato trovato per &nbsp;
                                  <strong>&quot;{filterName}&quot;</strong>.
                                  <br /> Prova a ricontrollare eventuali errori.
                                </Typography>
                              </Paper>

                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={total}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage="Per pagina"
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
              
            </>


          )}
        </Container>

        {/* CREAZIONE UTENTE  */}
        <div>
          <Dialog disableEscapeKeyDown open={openCrea} onClose={handleCloseMenuCrea}>
            <DialogTitle>Registra Utente</DialogTitle>
            {errCrea != '' ?
              <span style={{ color: "#FF4842" }} >{errCrea}</span>
              : <></>
            }
            <DialogContent>
              <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <TextField

                    name="email" label="Email"
                    onChange={handleEmailChange} />
                  <TextField
                    sx={{ mt: 2 }}
                    name="nome" label="Nome"
                    onChange={handleNomeChange} />
                  <TextField
                    sx={{ mt: 2 }}
                    name="cognome" label="Cognome"
                    onChange={handleCognomeChange} />
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DatePicker
                      format='DD/MM/YYYY'
                      sx={{ mt: 2 }}
                      name="birthDay" label="Data di nascita"
                      onChange={handleBirthDayChange}
                    />
                  </LocalizationProvider>

                  <TextField
                    sx={{ mt: 2 }}
                    name="fiscalCode" label="Codice fiscale"
                    onChange={handleFiscalCodeChange}
                  />
                  <Select
                    sx={{ mt: 2 }}
                    value={ruolo}
                    onChange={handleRuoloChange}
                  >
                    <MenuItem disabled value='default'>
                      <em>Ruolo</em>
                    </MenuItem>

                    <MenuItem value="ROLE_ADMIN">Admin</MenuItem>
                    <MenuItem value="ROLE_POLICE">Forze dell'ordine</MenuItem>
                    <MenuItem value="ROLE_PUBLIC">Istituzioni pubbliche</MenuItem>
                    <MenuItem value="ROLE_EXPERT">Esperto/a d'arte</MenuItem>
                    <MenuItem value="ROLE_ARTIST">Autore/Autrice</MenuItem>
                    <MenuItem value="ROLE_USER">Utente semplice</MenuItem>
                  </Select>

                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMenuCrea}>Annulla</Button>
              <Button onClick={handleOKCloseMenuCrea}>Crea</Button>
            </DialogActions>
          </Dialog>
        </div>

        {/* ELIMINA UTENTE  */}
        <div>
          <Dialog disableEscapeKeyDown open={openElimina} onClose={handleCloseMenuElimina}>
            <DialogTitle>Elimina Utente</DialogTitle>
            <DialogContent>
              <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Typography>
                    Sei sicuro di voler eliminare l' Utente ?
                  </Typography>
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMenuElimina}>Annulla</Button>
              <Button sx={{ color: 'error.main' }} onClick={handleOKCloseMenuElimina}>Elimina</Button>
            </DialogActions>
          </Dialog>
        </div>

        {/* SOSPENDI UTENTE  */}
        <div>
          <Dialog disableEscapeKeyDown open={openSospendi} onClose={handleCloseMenuSospendi}>
            <DialogTitle>Sospendi Utente</DialogTitle>
            <DialogContent>
              <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Typography>
                    Sei sicuro di voler sospendere l' Utente ?
                  </Typography>
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMenuSospendi}>Annulla</Button>
              <Button sx={{ color: 'error.main' }} onClick={handleOKCloseMenuSospendi}>Sospendi</Button>
            </DialogActions>
          </Dialog>
        </div>

        {/* MODIFICA UTENTE  */}
        <div>
          <Dialog disableEscapeKeyDown open={openModifica} onClose={handleCloseMenuModifica}>
            <DialogTitle>Modifca Ruolo Utente</DialogTitle>
            <DialogContent>
              <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Typography>
                    Seleziona il ruolo da assegnare all' Utente
                  </Typography>
                  <Select
                    sx={{ mt: 2 }}
                    value={ruoloEdit}
                    onChange={handleRuoloChangeEdit}
                  >
                    <MenuItem disabled value='default'>
                      <em>Ruolo</em>
                    </MenuItem>

                    <MenuItem value="ROLE_ADMIN">Admin</MenuItem>
                    <MenuItem value="ROLE_POLICE">Forze dell'ordine</MenuItem>
                    <MenuItem value="ROLE_PUBLIC">Istituzioni pubbliche</MenuItem>
                    <MenuItem value="ROLE_EXPERT">Esperto/a d'arte</MenuItem>
                    <MenuItem value="ROLE_ARTIST">Autore/Autrice</MenuItem>
                    <MenuItem value="ROLE_USER">Utente semplice</MenuItem>
                  </Select>

                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMenuModifica}>Annulla</Button>
              <Button onClick={handleOKCloseMenuModifica}>Conferma</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
}
