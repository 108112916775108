import axios from "./index";

export const getUser = async () => {

    // get the user dto
    const account = await axios.get(
        `/account`
    )
        .then((res) => res.data);
    return account;
}

export const checkLogin = async (email, password, rememberMe) => {
    const valid = await axios.post(
        `/authenticate`,
        { email, password, rememberMe }
    )
        .then((res) => { return true })
        .catch((err) => { return false })
    return valid;
}

export const login = async (email, password, rememberMe) => {
    localStorage.removeItem('jhi-authenticationToken');
    const token = await axios.post(
        `/authenticate`,
        { email, password, rememberMe }
    )
        .then((res) => res.data.id_token);

    localStorage.setItem('jhi-authenticationToken', token);
    return token;
}

export const logout = async () => {

    localStorage.removeItem('jhi-authenticationToken');
}

export const resetInit = async (email) => {

    const key = await axios.post(
        `/account/reset-password/init`,
        email,
        { headers: { "Content-Type": "text/plain" } }
    )
        .then((res) => res.data);

    return key;
}

export const resetFinish = async (key, newPassword) => {

    const res = await axios.post(
        `/account/reset-password/finish`,
        { key, newPassword }
    ).then((res) => res.data);

    return res;
}

export const signupBasic = async (role, email, password, firstName, lastName, birthDay, fiscalCode) => {

    const sex = "-";
    const skipEmail = false;
    const personId = await axios.post(
        `/people/step/basic`,
        { role, email, password, firstName, lastName, birthDay, fiscalCode, sex, skipEmail }
    )
        .then((res) => res.data.id).catch((err) => { throw err });
    await signupAdditional(personId);
}

const signupAdditional = async (personId) => {

    const pin = "000000";
    await axios.post(
        `/people/step/additional`,
        { personId, pin }
    )

}