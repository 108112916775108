import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Divider, Typography, Link, Stack, IconButton, InputAdornment, FormControlLabel, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
// api
import { login, logout, resetInit } from '../../../api/auth';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [notFound, setNotFound] = useState(false);

  const [dimenticata, setDimenticata] = useState(false);
  const [message, setMessage] = useState('');

  const handleClick = (event) => {
    event.preventDefault();
    login(email, password, rememberMe)
      .then((response) => {
        navigate('/dashboard', { replace: true });
      })
      .catch((error) => {
        setNotFound(true);
      });
  };

  const handleReset = () => {
    logout();
    resetInit(email)
      .then(() => {
        navigate('/login', { replace: true });
        setMessage("Le è stata inviata una mail con le istruzioni per reimpostare la password. ");
      })
      .catch((error) => {
        setNotFound(true);
      });
  };

  const handleSignupClick = () => {
    navigate('/register', { replace: true });
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberMe = (event) => {
    setRememberMe(event.target.checked);
  };

  return (
    <>
      {dimenticata ? (
        <>
          <Typography variant="h6" gutterBottom sx={{
            color: (theme) => theme.palette.primary.dark
          }}>
            Recupero Password
          </Typography>


          <Stack spacing={3}>

            {notFound ? (
              <span style={{ color: "#FF4842" }} >Email non trovata.</span>
            ) : (
              <></>
            )}

            <TextField

              name="email" label="Email"
              onChange={handleEmailChange} />

            <LoadingButton fullWidth size="large" type="reset" variant="contained" onClick={handleReset}>
              Conferma
            </LoadingButton>

            <a href='/login'>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <Link variant="subtitle2" underline="hover">
                  Torna a Login
                </Link>
              </Stack>
            </a>

            {message.length > 0 ? (
              <span style={{ color: 'primary.dark' }}>{message}</span>
            ) : (
              <></>
            )}
          </Stack>


        </>
      ) : (
        <>
          <form onSubmit={handleClick}>
            <Stack spacing={3}>

              {notFound ? (
                <span style={{ color: "#FF4842" }} >Utente o Password errati.</span>
              ) : (
                <></>
              )}

              <TextField

                name="email" label="Email"
                onChange={handleEmailChange} />

              <TextField

                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={handlePasswordChange} />
              {/* <FormControlLabel control={<Checkbox onChange={handleRememberMe} checked={rememberMe} />} label="Ricordami" /> */}
            </Stack>

            <br />
            <LoadingButton fullWidth size="large" type="submit" variant="contained">
              Accedi
            </LoadingButton>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <Link variant="subtitle2" underline="hover" onClick={() => { setDimenticata(true) }} >
                Password dimenticata?
              </Link>
            </Stack>

            <Divider sx={{ my: 2 }} />

            <LoadingButton fullWidth size="medium" type="reset" variant="contained" onClick={handleSignupClick} >
              Crea nuovo utente
            </LoadingButton>

            <a href='/home'>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <Link variant="subtitle2" underline="hover">
                  Torna alla Home
                </Link>
              </Stack>
            </a>

          </form>
        </>
      )}

    </>
  );
}
