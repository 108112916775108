import { useState, useEffect } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
//api
import { getUser, logout } from "../../api/auth";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState({ person: { id: 0, firstName: "", lastName: "" } });

  useEffect(() => {
    const load = async () => {
      const _user = await getUser();
      if (_user) {
        setUser(_user);
        setLoading(false);
      }
    }
    load();
  }, []);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />

      {loading ? (
        <img src="/assets/icons/Pulse-1s-200px.svg" />
      ) : (
        <Main>
          <Outlet context={{ user }} />
        </Main>
      )}

    </StyledRoot>
  );
}

export function useUser() {
  return useOutletContext();
}
