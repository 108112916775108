// imports the React Javascript Library
import React from "react";
//Card
import { Card, Button, Grid } from '@mui/material';

//Tabs
import { styled } from '@mui/material/styles';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const styles = theme => ({
  root: {
    width: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  input: {
    display: "none"
  },
  img: {
    width: 10,
    height: 10,
    margin: "auto",
    display: "block",
    maxWidth: "10%",
    maxHeight: "10%"
  }
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

class ImageUploadCard extends React.Component {
  state = {
    mainState: this.props.imgSrc != null ? "uploaded" : "initial",
    imageUploaded: 0,
    selectedFile: this.props.imgSrc
  };

  handleUploadClick = event => {

    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      this.setState({
        selectedFile: [reader.result]
      });
    }.bind(this);

    this.setState({
      mainState: "uploaded",
      selectedFile: event.target.files[0],
      imageUploaded: 1
    });
  };

  renderInitialState() {
    const { classes, theme, label, id, disabled } = this.props;
    const { value } = this.state;
    const _id = `img${id}`;
    return (
      <>
        {this.state.selectedFile ??
          <Grid item>
            <img
              id={_id}
              height="100%"
              width="220px"
              src={this.state.selectedFile}
            />
          </Grid>
        }
        <Grid container direction="column" alignItems="flex-end">
          <label htmlFor={id}>
            <Button disabled={disabled} sx={{ minHeight: 61.75 }} startIcon={<CloudUploadIcon />} variant="contained" component="span">
              {label}
              <VisuallyHiddenInput disabled={disabled} name={id}
                accept="application/pdf,image/jpg,image/jpeg,image/png"
                id={id}
                multiple
                type="file"
                onChange={this.handleUploadClick}
              />
            </Button>
          </label>
        </Grid>
      </>
    );
  }

  renderUploadedState() {
    const { classes, theme, label, id, disabled } = this.props;
    const _id = `img${id}`;
    return (
      <>
        <Grid item>
          <img
            id={_id}
            height="100%"
            width="220px"
            src={this.state.selectedFile}
          />
        </Grid>
        <Grid container direction="column" alignItems="flex-end">
          <label htmlFor={id}>
            <Button disabled={disabled} sx={{ minHeight: 61.75 }} startIcon={<CloudUploadIcon />} variant="contained" component="span">
              {label}
              <VisuallyHiddenInput disabled={disabled} name={id}
                accept="application/pdf,image/jpg,image/jpeg,image/png"
                id={id}
                multiple
                type="file"
                onChange={this.handleUploadClick}
              />
            </Button>
          </label>
        </Grid>
      </>
    );
  }

  render() {
    const { classes, theme } = this.props;

    return (
      <div>
        <Card>
          {(this.state.mainState == "initial" && this.renderInitialState()) ||
            (this.state.mainState == "uploaded" && this.renderUploadedState())}
        </Card>
      </div>
    );
  }
}
export default styled(ImageUploadCard)({ styles });

