// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Home',
    path: '/dashboard/app',
    icon: icon('ic_home'),
  },
  {
    title: 'Autori',
    path: '/dashboard/autori',
    icon: icon('ic_brush'),
  },
  {
    title: 'Opere',
    path: '/dashboard/opere',
    icon: icon('ic_gallery'),
  },
  {
    title: 'Utenti',
    path: '/dashboard/utenti',
    icon: icon('ic_user_alt'),
  },
  {
    title: 'Utenti non verificati',
    path: '/dashboard/utenti2',
    icon: icon('ic_user_alt'),
  },
  {
    title: 'Assistenza',
    path: '/dashboard/assistenza',
    icon: icon('twotone-help'),
  }
];

export default navConfig;
