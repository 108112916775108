import axios from "./index";

export const listAccounts = async (currentPage, itemsPerPage, sort, sortBy) => {

    const USER_LIST = await axios.post(
        `/account/all`,
        { currentPage, itemsPerPage, sort, sortBy }
    )
        .then((res) => res);
    return USER_LIST;

    // Response headers :
    // x-total-count (number)

    // Response body :
    // data (array)
}

export const searchAccounts = async (currentPage, itemsPerPage, sort, sortBy, query) => {
    const personId = parseInt(query);
    const nome = query;
    const cognome = query;
    const email = query;

    const USER_LIST = await axios.post(
        `/account/search`,
        { currentPage, itemsPerPage, sort, sortBy, personId, nome, cognome, email }
    )
        .then((res) => res);
    return USER_LIST;

    // Response headers :
    // x-total-count (number)

    // Response body :
    // data (array)
}

export const activate = async (key) => {

    await axios.get(
        `/activate?key=${key}`
    )

}

export const changeEmail = async (oldEmail, newEmail) => {

    try {
        await axios.post(
            '/email',
            { oldEmail, newEmail }
        )
    } catch (error) {
        return error.response.data;
    }
}

export const changeAnagrafica = async (personId, firstName, lastName, birthDay, fiscalCode) => {

    const [req] = [{personId: personId, firstName: firstName, lastName: lastName, birthDay: birthDay, fiscalCode: fiscalCode}].filter(item => item !== undefined && item !== null);
    try {
        await axios.post(
            '/people/step/additional/update',
            req
        )
    } catch (error) {
        return error.response.data;
    }
}

export const changeRole = async (id, fromRole, toRole) => {
    await axios.post(
        '/account/role',
        { id, fromRole, toRole }
    )
}

export const deleteAccount = async (id) => {

    try {
        await axios.delete(
            `/account?id=${id}`,
        )
    } catch (error) {
        return error.response.data;
    }
}

export const banAccount = async (id) => {
    try {
        await axios.get(
            `/account/ban?id=${id}`,
        )
    } catch (error) {
        return error.response.data;
    }
}

export const assistanceMail = async (subject, content, sender) => {

    try {
        await axios.post(
            '/mail/send',
            { subject, content, sender }
        )
    } catch (error) {
        return error.response.data;
    }
}