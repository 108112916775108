import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';

import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  FormControl,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
// mock
import { activate, listAccounts, deleteAccount } from 'src/api/user';

import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'fullName', label: 'Nome', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'role', label: 'Ruolo', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.person.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

function roleToLabel(role) {
  if (role === "ROLE_SUPERADMIN") return "Super Admin"
  if (role === "ROLE_ADMIN") return "Admin"
  if (role === "ROLE_POLICE") return "Forze dell'ordine"
  if (role === "ROLE_PUBLIC") return "Istituzioni pubbliche"
  if (role === "ROLE_EXPERT") return "Esperto/a d'arte"
  if (role === "ROLE_ARTIST") return "Autore/Autrice"
  if (role === "ROLE_USER") return "Utente semplice"
}

export default function UserPageToverify() {
  const [open2, setOpen2] = useState(null);

  const [openElimina, setOpenElimina] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('id');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(parseInt(sessionStorage.getItem('rowsPerPage')) || 5);

  const [userList, setUserList] = useState([]);
  const [userPendingList, setUserPendingList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);

  // ============ Menu Elimina ===============

  const handleOpenMenuElimina = (event, authorId) => {
    setSelectedItem(authorId);
    setOpenElimina(event.currentTarget);
  }

  const handleCloseMenuElimina = () => {
    setOpenElimina(null);
  }
  const handleOKCloseMenuElimina = async () => {
    await deleteAccount(selectedItem)
      .then().finally(() => {
        navigate(0);
      });
    setOpenElimina(null);
  }

  // =========================================

  const navigate = useNavigate();

  const handleAttiva = async (key) => {
    await activate(key).then().finally(() => { navigate(0) });
    setOpen2(false);
  }

  const filteredUsers = userList;

  const isNotFound = !filteredUsers.length && !!filterName;

  useEffect(() => {
    const load = async () => {
      var _list = await listAccounts(page, 30, order, orderBy);
      if (_list) {
        _list.data.forEach(user => {
          user.ruolo = roleToLabel(user.authorities[0])
        });
        setUserList(_list.data.filter(u => u.person !== null && !u.activated));
        setUserPendingList(_list.data.filter(u => u.person !== null && !u.activated));
        setTotal(Number(_list.headers["x-total-count"]));
      }
    }
    load();
  }, [page, rowsPerPage, order, orderBy]);

  useEffect(() => {
    const load = async () => {
      setTotal(userList.length);
      console.log(userList)
      setLoading(false);
    }
    load();
  }, [userList]);

  return (
    <>
      <div>
        <Helmet>
          <title> Utenti non verificati | Registro Arte </title>
        </Helmet>

        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Lista Utenti
            </Typography>
          </Stack>


          <Card>
            <Typography variant="h5" gutterBottom>
              
            </Typography>

            <Scrollbar>
              <TableContainer>
                <Table>
                  <TableBody>
                    {userList.map((row) => {
                      const { id, ruolo, authorities, person, activationKey } = row;
                      const { firstName, lastName, email, birthDay, fiscalCode } = person;

                      return (
                        <TableRow>
                          <TableCell>

                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>

                              <Typography variant="subtitle2" noWrap>
                                {id}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{firstName} {lastName}</TableCell>

                          <TableCell align="left">{email}</TableCell>

                          <TableCell align="left">{ruolo}</TableCell>

                          <TableCell align="left">{birthDay}</TableCell>

                          <TableCell align="left">{fiscalCode}</TableCell>

                          <TableCell align="right">
                            <Button title='Conferma registrazione Utente' onClick={(event) => { handleAttiva(activationKey) }}>
                              <Iconify icon={'mingcute:check-2-fill'} sx={{ mr: 2 }} />

                            </Button>

                            <Button onClick={(event) => { handleOpenMenuElimina(event, id) }} title='Cancella registrazione Utente' sx={{ color: 'error.main' }}>
                              <Iconify icon={'mdi:cancel-outline'} sx={{ mr: 2 }} />

                            </Button>
                          </TableCell>

                        </TableRow>

                      );
                    })}
                  </TableBody>


                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>

        </Container>

        {/* ELIMINA UTENTE  */}
        <div>
          <Dialog disableEscapeKeyDown open={openElimina} onClose={handleCloseMenuElimina}>
            <DialogTitle>Elimina Utente</DialogTitle>
            <DialogContent>
              <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Typography>
                    Sei sicuro di voler eliminare l' Utente ?
                  </Typography>
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMenuElimina}>Annulla</Button>
              <Button sx={{ color: 'error.main' }} onClick={handleOKCloseMenuElimina}>Elimina</Button>
            </DialogActions>
          </Dialog>
        </div>

      </div>
    </>
  );
}