import axios from "./index";

export const byLicensePlate = async (licensePlate) => {
    try {
        await axios.get(`/asset/certificate/${licensePlate}`);
        const asset = await axios.get(
            `/asset/license-plate/${licensePlate}`
        )
            .then((res) => res.data);
        return asset;
    } catch (error) {
        if (error.response.status == 404) {
            return 404;
        }
    }

}

export const findHistory = async (assetId, currentPage, itemsPerPage, sort, sortBy) => {

    var history = await axios.post(
        '/asset-hist',
        { assetId, currentPage, itemsPerPage, sort, sortBy }
    )
    var data = history.data;
    return { data, headers: history.headers };

    // Response headers :
    // x-total-count (number)

    // Response body :
    // data (array)
}

export const listAssets = async (currentPage, itemsPerPage, sort, sortBy) => {

    var ASSET_LIST = await axios.post(
        `/asset/all`,
        { currentPage, itemsPerPage, sort, sortBy }
    )
        .then((res) => {

            const data = res.data.map(item => ({
                id: item.id,
                autore: item.autore,
                titolo: item.titolo,
                descrizione: item.descrizione,
                licensePlate: item.licensePlate,
                nft: item.nft,
                wallet: item.wallet,
            }));

            res.data = data;

            return res;
        });
    var data = ASSET_LIST.data;
    data = data.filter(item => !item.licensePlate.includes('_DELETE'));
    return { data, headers: ASSET_LIST.headers };

    // Response headers :
    // x-total-count (number)

    // Response body :
    // data (array)
}

export const searchAssets = async (currentPage, itemsPerPage, sort, sortBy, query, userId) => {
    const titolo = query;
    const autore = query;
    const descrizione = query;
    const licensePlate = query;
    const ASSET_LIST = await axios.post(
        `/asset/search`,
        { currentPage, itemsPerPage, sort, sortBy, titolo, autore, descrizione, licensePlate, userId }
    )
        .then((res) => res);
    var data = ASSET_LIST.data;
    data = data.filter(item => !item.licensePlate.includes('_DELETE'));
    return { data, headers: ASSET_LIST.headers };

    // Response headers :
    // x-total-count (number)

    // Response body :
    // data (array)
}


export const searchAuthor = async (currentPage, itemsPerPage, sort, sortBy, query) => {
    const nome = query;
    const cognome = query;
    const bio = query;
    const birthday = query;
    const scuola = query;
    const author_LIST = await axios.post(
        `/author/search`,
        { currentPage, itemsPerPage, sort, sortBy, nome, cognome, bio, birthday, scuola }
    )
        .then((res) => res);
    return author_LIST;

    // Response headers :
    // x-total-count (number)

    // Response body :
    // data (array)
}

export const listAuthors = async (currentPage, itemsPerPage, sort, sortBy) => {

    const A_LIST = await axios.post(
        `/author/all`,
        { currentPage, itemsPerPage, sort, sortBy }
    )
        .then((res) => res);
    return A_LIST;

    // Response headers :
    // x-total-count (number)

    // Response body :
    // data (array)
}

export const editAuthor = async (id, nome, cognome, bio, scuola, birthday) => {

    try {
        const newAuthor = await axios.put(
            '/author',
            { id, nome, cognome, bio, birthday }
        )
            .then((res) => res.data);
        return newAuthor;
    } catch (error) {
        return error.response.data;
    }
}

export const deleteAsset = async (licensePlate) => {
    try {
        await axios.delete(
            `/asset?licensePlate=${licensePlate}`,
        )
    } catch (error) {
        return error.response.data;
    }
}

export const createAsset = async (form) => {
    form = { ...form, timeout: 60 };
    const { id, titolo, autore, categoria,
        tipologia, materiale, tecnica, datazione,
        images, personId, descrizione, provenienza,
        dirittiAutore, dirittiProprieta,
        dimensioni, ubicazione, luogo, documentatoIn, nprotocollo,
        indicazioni, certificato, enteCertificato, dataCertificato,
        citazioni, tipologiaCitazioni, dataCitazioni, enteCitazioni,
        esposizionePresso, pressoEnte, pressoData, pressoLuogo,
        interventi, enteInterventi, dataInterventi, vincolo,
        beneCulturale, valore, dataStima, stimatoDa, pin, timeout, authorId } = form;
    const txId = await axios.post(
        `/asset/async`,
        {
            id, titolo, autore, categoria,
            tipologia, materiale, tecnica, datazione,
            images, personId, descrizione, provenienza,
            dirittiAutore, dirittiProprieta,
            dimensioni, ubicazione, luogo, documentatoIn, nprotocollo,
            indicazioni, certificato, enteCertificato, dataCertificato,
            citazioni, tipologiaCitazioni, dataCitazioni, enteCitazioni,
            esposizionePresso, pressoEnte, pressoData, pressoLuogo,
            interventi, enteInterventi, dataInterventi, vincolo,
            beneCulturale, valore, dataStima, stimatoDa, pin, timeout, authorId
        }
    )
        .then((res) => res.data);
    return txId;
}

export const createAuthor = async (nome, cognome, bio, scuola, birthday) => {

    const author = await axios.post(
        `/author/create`,
        { nome, cognome, bio, scuola, birthday }
    )
        .then((res) => res.data)
    return author;

}