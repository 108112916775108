import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Card, Box, FormControl, Stack, TextField, Container, Typography, Button } from '@mui/material';
// components
// sections
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import useResponsive from 'src/hooks/useResponsive';
import Iconify from 'src/components/iconify/Iconify';
import { useState } from 'react';
import { getUser } from 'src/api/auth';
import { assistanceMail } from 'src/api/user';
// icons
import SendIcon from '@mui/icons-material/Send';

// ----------------------------------------------------------------------

export default function HelpPage() {
  const theme = useTheme();
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');

  const [message, setMessage] = useState('');

  const mdUp = useResponsive('up', 'md');

  const handleMail = async () => {
    const user = await getUser();
    if (subject.length > 0 && body.length > 0) {
      await assistanceMail(subject, body, user.login).then((res) => {
        setMessage('Abbiamo inoltrato la sua richiesta all\'assistenza.')
      });
    }
  }

  return (
    <>
      <Helmet>
        <title> Assistenza | Registro Arte </title>
      </Helmet>

      <Container>
        <Card sx={{ padding: 4 }}>
          <Scrollbar>
            <Typography justifyContent='center' variant="h4" gutterBottom>
              Assistenza
            </Typography>

            <FormControl sx={{ width: '100%' }}>
              <Stack mt={1} spacing={2} >

                <Typography color='text.secondary'>MOTIVO DELLA RICHIESTA</Typography>
                <TextField onChange={(event) => { setSubject(event.target.value) }} />

                <Typography color='text.secondary'>MESSAGGIO</Typography>
                <TextField onChange={(event) => { setBody(event.target.value) }} multiline rows={10} />

              </Stack>
            </FormControl>

          </Scrollbar>
          {message != '' ? (
            <span>{message}</span>
          ) : (
            <></>
          )}
          <Button onClick={handleMail} sx={{ display: 'flex', mt: 4, ml: '85%' }} endIcon={<SendIcon />} variant="contained">
            Invia messaggio
          </Button>
        </Card>

      </Container>

    </>
  );
}
