import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Card, MenuItem, Container, Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { byLicensePlate } from 'src/api/art';
import { AppCardSummary } from 'src/sections/@dashboard/app';
import AppImagePreview from 'src/sections/@dashboard/app/AppImagePreview';
import { useNavigate } from 'react-router-dom';
import Iconify from 'src/components/iconify';

import Logo from 'src/components/logo/Logo';

// ----------------------------------------------------------------------

export default function CertificatePage() {

    const [titolo, setTitolo] = useState('');
    const [autore, setAutore] = useState('');
    const [provenienza, setProvenienza] = useState('');
    const [tipologia, setTipologia] = useState('');
    const [createdDate, setCreatedDate] = useState('');
    const [imagePath, setImagePath] = useState('');
    const [rarible, setRarible] = useState('');
    const [polygon, setPolygon] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const load = async () => {
            const queryParameters = new URLSearchParams(window.location.search)
            const licensePlate = queryParameters.get("qr");
            const asset = await byLicensePlate(licensePlate);
            if (asset === 404) {
                navigate('/404', { replace: true })
            }

            setTitolo(asset.titolo);
            setAutore(asset.autore);
            setProvenienza(asset.provenienza);
            setTipologia(asset.tipologia);
            setCreatedDate(asset.nft.createdDate);
            setImagePath(asset.images.find((img) => img.type == "ASSET_PICTURE").fileContent.path);

            setRarible(`https://rarible.com/token/polygon/${asset.nft.contractId}:${asset.nft.tokenId}`);
            setPolygon(`https://polygonscan.com/tx/${asset.nft.transactions[0].hash}`)
        }
        load();
    }, []);

    const body = <>
        <Typography variant="body2">
            AUTORE/AUTRICE : {autore} <br />
            PROVENIENZA : {provenienza} <br />
            TIPOLOGIA : {tipologia} <br />
            DATA REGISTRAZIONE : {new Date(createdDate).toLocaleDateString('it-IT', { year: "numeric", month: "short", day: "numeric" })}
        </Typography>
    </>

    return (
        <>
            <Helmet>
                <title>Certificato | Registro Arte</title>
            </Helmet>
            <Grid container padding={3}
                alignItems="center">

                <Grid item xs={4} ><Logo /></Grid>
                <Grid item xs={5} >
                    <Typography variant="h6" sx={{ paddingLeft: 3, color: 'text.secondary' }}>
                        Tracciabilità dei beni culturali e opere
                    </Typography>
                </Grid>
            </Grid>
            <Box sx={{
                backgroundColor: 'primary.dark',
                height: 35,
                width: '100%',
            }} />
            <Container>
                <Card>
                    <Stack justifyContent='center' direction="row">
                        <Card>
                            <Box>
                                <img style={{maxHeight:'500px'}} src={imagePath} />
                            </Box>
                        </Card>
                    </Stack>
                    <Stack justifyContent='center' direction="row">
                        <MenuItem>
                            <a target='blank' href={rarible}>
                                <Iconify icon='simple-icons:rarible' ></Iconify>
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <a target='blank' href={polygon}>
                                <Iconify icon='devicon-plain:polygon' ></Iconify>
                            </a>
                        </MenuItem>
                    </Stack>
                    <AppCardSummary title={titolo} body={body} ></AppCardSummary>
                </Card>
            </Container>
        </>
    );
}
