import { Helmet } from 'react-helmet-async';
import { reject } from 'lodash';
import { useState, useRef, useEffect } from 'react';
// @mui
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import {
  Alert,
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Select,
  Button,
  FormControlLabel,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  FormControl,
  TextField,
  TableContainer,
  TablePagination,
  Grid,
  Box,
  InputLabel,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import { byLicensePlate, deleteAsset, listAssets, listAuthors, searchAssets, findHistory } from 'src/api/art';
import { useUser } from 'src/layouts/dashboard/DashboardLayout';
// style
import './style/new.css';
// image upload
import ImageUpload from 'src/utils/ImageUpload';
// asset api
import { createAsset } from 'src/api/art';

import { useTheme } from '@mui/material/styles';
import AutoComplete from 'src/sections/@dashboard/art/MapAutocomplete';
import { useNavigate } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';

import useResponsive from 'src/hooks/useResponsive';

import { useReactToPrint } from 'react-to-print';
import { getUser } from 'src/api/auth';

// ----------------------------------------------------------------------

const categoriaOpts = [
  "STORICO - ARTISTICO",
  "ARCHEOLOGICO",
  "ETNOANTROPOLOGICO",
  "ARCHIVISTICO",
  "ARCHITETTONICO",
  "ALTRO"
];

const tipologiaOpts = [
  "SCULTURA",
  "DIPINTO",
  "NUMISMATICA",
  "MANOSCRITTO",
  "LIBRO",
  "SPARTITO",
  "MATERIALE FOTOGRAFICO E NEGATIVI",
  "ARREDO",
  "ARAZZO O TESSILE",
  "MATRICI O INCISIONI",
  "FOSSILI O MINERALI",
  "CERAMICHE O VASELLAME (FRAMMENTI)",
  "ARMI ANTICHE",
  "STRUMENTI MUSICALI",
  "INSTALLAZIONE",
  "ALTRO"
];

const materialeOpts = [
  "TELA",
  "LEGNO",
  "TERRACOTTA",
  "MARMO",
  "CRETA",
  "CARTA",
  "PAPIRO",
  "PERGAMENA",
  "VETRO",
  "ALTRO"
];

const tecnicaOpts = [
  "OLIO",
  "TEMPERA",
  "ACRILICO",
  "MOSAICO",
  "INTARSIO",
  "ENCAUSTO",
  "AFFRESCO",
  "TECNICA MISTA (ES MARMO + RAME ECC)",
  "ALTRO"
];

const provenienzaOpts = [
  "ACQUISTO (collezione privata - galleria d'arte - antiquario - casa d'asta)",
  "EREDITA'",
  "DONAZIONE",
  "RITROVAMENTO FORTUITO",
  "CONTRATTO DI CESSIONE DEI DIRITTI DISFRUTTAMENTO ECONOMICO",
  "ALTRO"
];

const ubicazioneOpts = [
  "GALLERIA",
  "MUSEO",
  "CASA PRIVATA",
  "DEPOSITO-MAGAZZINO",
  "BANCA",
  "ALTRO"
];

const documentataInOpts = [
  "INVENTARIO",
  "CATALOGO",
  "ARCHIVIO",
  "ALTRO"
];

const datazioneOpts = [
  "ANNO",
  "SECOLO",
  "NON DATABILE",
];

const secoli = [
  "I",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
  "X",
  "XI",
  "XII",
  "XIII",
  "XIV",
  "XV",
  "XVI",
  "XVII",
  "XVIII",
  "XIX",
  "XX",
  "XXI",
  "XXII",
  "XXIII",
];

const definizione = "Questa opera è un bene culturale? * (definizione: Con Bene Culturale si indicano le cose mobili ed immobili che presentano interesse artistico, storico, archeologico, etnoantropologico, archivistico e bibliografico e le altre cose individuate dalla legge quali testimonianze aventi valore di civiltà)";

const TABLE_HEAD = [
  { id: 'licensePlate', label: 'ID Opera (NFT)', alignRight: false },
  { id: 'autore', label: 'Autore', alignRight: false },
  { id: 'titolo', label: 'Titolo opera/bene', alignRight: false },
  { id: 'createdDate', label: 'Data di registrazione', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function toTimestamp(dateOld) {
  const date = new Date(dateOld);
  return new Intl.DateTimeFormat('it-IT', {
    dateStyle: 'medium',
    timeStyle: 'long',
    timeZone: 'Europe/Rome',
  }).format(date)
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const CustomSelect = ({ list, title, name, form, errorForm, handler, altro, isEditing }) => (
  <>
    <Typography color='text.secondary'>{title}</Typography>
    <FormControl>
      <Select disabled={!isEditing} error={errorForm[name]} name={name} onChange={handler} value={(form[name] && form[name] != altro) ? form[name] : "default"}>
        <MenuItem disabled value='default'>
          <em>Scegli un opzione</em>
        </MenuItem>
        {list.map((opt) => (
          <MenuItem value={opt}>{opt}</MenuItem>
        ))}

      </Select>
      {form[name] == altro || (form[name] != null && !list.includes(form[name])) ?
        <TextField key={`${name}-input`} value={form[name]} onChange={handler}
          name={name} label="Specifica" sx={{ mt: 2 }}
        />
        :
        <></>
      }
    </FormControl>
  </>
)

const getBase64 = (file) => {
  return new Promise(resolve => {

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };

  })
}


export default function ArtPage() {

  const [page, setPage] = useState(0);

  const [openCrea, setOpenCrea] = useState(false);
  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const [orderBy, setOrderBy] = useState('id');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(parseInt(sessionStorage.getItem('rowsPerPage')) || 5);

  const [AssetList, setAssetList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [openElimina, setOpenElimina] = useState(null);

  const [total, setTotal] = useState(0);
  const [authors, setAuthors] = useState([]);

  const [valoreOpt, setValoreOpt] = useState('');
  const [accettaCondizioni, setAccettaCondizioni] = useState(false);
  const [errCondizioni, setErrCondizioni] = useState(false);
  const [err, setErr] = useState('');

  // links
  const [rarible, setRarible] = useState('');
  const [polygon, setPolygon] = useState('');
  const [certificate, setCertificate] = useState('');

  // ============  FORM ===========================
  const [form, setForm] = useState({});
  const [errorForm, setErrorForm] = useState({});

  const [isEdit, setIsedit] = useState(false);
  const [isEditing, setIsediting] = useState(true);
  const [isMinting, setIsMinting] = useState(false);

  const [anno, setAnno] = useState(0);
  const [secolo, setSecolo] = useState('');
  const [acdc, setAcdc] = useState('');
  const [datazioneScelta, setDatazioneScelta] = useState('');

  const fromDate = (value) => {
    var date = new Date(value);

    // Get year, month, and day part from the date
    var year = date.toLocaleString("default", { year: "numeric" });
    var month = date.toLocaleString("default", { month: "2-digit" });
    var day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    var formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }

  const handleAccettaCondizioni = (event) => {
    setAccettaCondizioni(event.target.checked)
  }

  const handleAnno = (event) => {
    if (event.target) {
      setAnno(event.target.value);
    }
  }

  const handleSecolo = (event) => {
    if (event.target) {
      setSecolo(event.target.value);
    }
  }

  const handleAcDc = (event) => {
    if (event.target) {
      setAcdc(event.target.value);
    }
  }

  const handleSelectDate = (value, name) => {

    value = fromDate(value);

    setForm({
      ...form,
      [name]: value
    });

  }

  const handleDatazioneScelta = (event) => {
    setDatazioneScelta(event.target.value);
  }

  const handleSelect = (event) => {
    var value = event.target.value;
    setForm({
      ...form,
      [event.target.name]: value
    });
  }

  const handleForm = (newForm) => {
    setForm(newForm);
  }

  const handleEditingOpera = (event) => {
    if (user.id === form.user.id) {
      setIsediting(!isEditing);
    }
  }

  // ============ Menu Elimina ===============

  const handleOpenMenuElimina = (event, licensePlate) => {
    setSelectedItem(licensePlate);
    setOpenElimina(event.currentTarget);
  }

  const handleCloseMenuElimina = () => {
    setOpenElimina(null);
  }
  const handleOKCloseMenuElimina = async () => {
    await deleteAsset(selectedItem)
      .then().finally(() => {
        navigate(0);
      });
    setOpenElimina(null);
  }

  // =============================================

  const { user } = useUser();

  const handleOpenMenuCrea = (event) => {
    setOpenCrea(true);
    listAuthors(0, 1, 'asc', 'id')
      .then((res) => {
        const totalCount = res.headers["x-total-count"];
        listAuthors(0, totalCount, 'asc', 'id')
          .then((list) => {
            setAuthors(list.data);
          })
      })
  }

  const handleCloseMenuCrea = () => {
    setOpenCrea(false);
    setIsedit(false);
    setIsediting(true);
    setForm({});
  }

  const handleCreaOpera = async () => {

    if (!accettaCondizioni) {
      setErrCondizioni(true);
      window.scrollTo(0, 0);
      return;
    } else {
      setErrCondizioni(false);
    }
    if (form.authorId == null) {
      setErrorForm({ ...errorForm, authorId: "Missing" })
      window.scrollTo(0, 0);
      return
    }

    setIsMinting(true);

    var fronte = document.getElementsByName("fronte")[0].files[0];
    if (fronte.size > 2097152) {
      setErr('Dimensione immagine fronte troppo grande (max 2MB)');
      window.scrollTo(0, 0);
      setIsMinting(false);
      return;
    } else if (fronte == null && form.images != null && form.images.find((img) => img.type == "ASSET_PICTURE") != null) {
      const base64Data = form.images.find((img) => img.type == "ASSET_PICTURE").fileContent.content;
      const base64Response = await fetch(`data:${form.images.find((img) => img.type == "ASSET_PICTURE").fileContent.contentType};base64,${base64Data}`);
      const blob = await base64Response.blob();
      fronte = blob;
    }
    var retro = document.getElementsByName("retro")[0].files[0];
    if (retro != null && retro.size > 2097152) {
      setErr('Dimensione immagine retro troppo grande (max 2MB)');
      window.scrollTo(0, 0);
      setIsMinting(false);
      return;
    } else if (retro == null && form.images != null && form.images.find((img) => img.type == "ASSET_PICTURE_RETRO") != null) {
      const base64Data = form.images.find((img) => img.type == "ASSET_PICTURE_RETRO").fileContent.content;
      const base64Response = await fetch(`data:${form.images.find((img) => img.type == "ASSET_PICTURE_RETRO").fileContent.contentType};base64,${base64Data}`);
      const blob = await base64Response.blob();
      retro = blob;
    }
    var receipt = document.getElementsByName("receipt")[0].files[0];
    if (receipt.size > 2097152) {
      setErr('Dimensione immagine ricevuta troppo grande (max 2MB)');
      window.scrollTo(0, 0);
      setIsMinting(false);
      return;
    } else if (receipt == null && form.images != null && form.images.find((img) => img.type == "PAYMENT_RECEIPT") != null) {
      const base64Data = form.images.find((img) => img.type == "PAYMENT_RECEIPT").fileContent.content;
      const base64Response = await fetch(`data:${form.images.find((img) => img.type == "PAYMENT_RECEIPT").fileContent.contentType};base64,${base64Data}`);
      const blob = await base64Response.blob();
      receipt = blob;
    }
    var signs = document.getElementsByName("signs")[0].files[0];
    if (signs != null && signs.size > 2097152) {
      setErr('Dimensione immagine segni particolari troppo grande (max 2MB)');
      window.scrollTo(0, 0);
      setIsMinting(false);
      return;
    } else if (signs == null && form.images != null && form.images.find((img) => img.type == "PARTICULAR_SIGNS") != null) {
      const base64Data = form.images.find((img) => img.type == "PARTICULAR_SIGNS").fileContent.content;
      const base64Response = await fetch(`data:${form.images.find((img) => img.type == "PARTICULAR_SIGNS").fileContent.contentType};base64,${base64Data}`);
      const blob = await base64Response.blob();
      signs = blob;
    }

    var images = [];


    await Promise.all([
      getBase64(fronte).then(result => {
        if (result.includes('application/pdf')) {
          setErr('Formato PDF non valido per immagine Opera fronte/ retro');
          window.scrollTo(0, 0);
          setIsMinting(false);
          reject();
        }
        images.push(
          {
            type: "ASSET_PICTURE",
            fileContent: {
              content: result.replace(/^data:(image\/\w+|application\/pdf);base64,/, "")
            }
          }
        )
      }).catch((err) => { setIsMinting(false); setErr("Errore: Inserire tutte le immagini obbligatorie (fronte e ricevuta)."); window.scrollTo(0, 0); }),

      getBase64(receipt).then(result => {
        images.push(
          {
            type: "PAYMENT_RECEIPT",
            fileContent: {
              content: result.replace(/^data:(image\/\w+|application\/pdf);base64,/, "")
            }
          }
        )
      }).catch((err) => { setIsMinting(false), setErr("Errore: Inserire tutte le immagini obbligatorie (fronte e ricevuta)."); window.scrollTo(0, 0); }),

    ]);

    if (retro != null) {
      await getBase64(retro).then(result => {
        if (result.includes('data:application/pdf')) {
          setErr('Formato PDF non valido per immagine Opera fronte/ retro');
          window.scrollTo(0, 0);
          setIsMinting(false);
          reject();
        }
        images.push(
          {
            type: "ASSET_PICTURE_RETRO",
            fileContent: {
              content: result.replace(/^data:(image\/\w+|application\/pdf);base64,/, "")
            }
          }
        )
      })
    }

    if (signs != null) {
      await getBase64(signs).then(result => {
        images.push(
          {
            type: "PARTICULAR_SIGNS",
            fileContent: {
              content: result.replace(/^data:(image\/\w+|application\/pdf);base64,/, "")
            }
          }
        )
      })

    }

    if (datazioneScelta == "ANNO") {
      const _form = form;
      _form.datazione = anno;
      setForm(_form);
    }
    if (datazioneScelta == "NON DATABILE") {
      const _form = form;
      _form.datazione = "NON DATABILE";
      setForm(_form);
    }
    if (datazioneScelta == "SECOLO") {
      const _form = form;
      _form.datazione = `${secolo} ${acdc}`;
      setForm(_form);
    }
    if (images.length > 0) {
      setForm({
        ...form,
        images: images
      })
    }

  }

  const handleRequestSort = (event, property) => {
    //
  };

  const handleSelectAllClick = (event) => {
    //
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    sessionStorage.setItem('rowsPerPage', event.target.value);
  };

  const handleFilterByName = async (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const handleHistory = async (id, licensePlate) => {
    navigate(`/dashboard/storico?id=${id}&opera=${licensePlate}`);
  }

  const handleEdit = async (licensePlate) => {
    const editForm = await byLicensePlate(licensePlate);
    editForm.authorId = editForm.author.id;
    editForm.id = null;
    const dat = editForm.datazione;
    if (dat == "NON DATABILE") {
      setDatazioneScelta(dat);
    }
    else if (dat.includes(" ")) {
      setSecolo(dat.split(" ")[0]);
      setAcdc(dat.split(" ")[1]);
      setDatazioneScelta("SECOLO")
    } else {
      setAnno(dat);
      setDatazioneScelta("ANNO")
    }
    setForm(editForm);
    setIsedit(true);
    setIsediting(false);
    setRarible(`https://rarible.com/token/polygon/${editForm.nft.contractId}:${editForm.nft.tokenId}`);
    setPolygon(`https://polygonscan.com/tx/${editForm.nft.transactions[0].hash}`)
    setCertificate(`https://storage.googleapis.com/ra-bucket-photos/certificate/${editForm.licensePlate}pdf`)
    handleOpenMenuCrea(null);
  }

  const navigate = useNavigate();

  const componentRef = useRef();

  const filteredAssets = AssetList;

  const isNotFound = !filteredAssets.length && !!filterName;

  const mdUp = useResponsive('up', 'md');

  useEffect(() => {
    const load = async () => {
      const u = await getUser();
      const a = authors.find(a => a.id === form.authorId);
      var req =
      {
        ...form,
        autore: `${a.nome} ${a.cognome}`,
        personId: u.person.id
      }
      if (isEdit) {
        const { id } = AssetList.find((a) => a.licensePlate === form.licensePlate);
        req = {
          ...req,
          id: id
        }
      }
      await createAsset(req)
        .then((res) => {
          setIsMinting(false);
          navigate(0);
        })
        .catch((err) => {
          setErr("Errore nella registrazione dell'Opera , ricontrollare i campi inseriti (immagini jpg/png o pdf)");
          window.scrollTo(0, 0);
          setIsMinting(false);
        })
    }
    if (isMinting && form.images != null && form.images.find((img) => img.type == "ASSET_PICTURE") != null && form.images.find((img) => img.type == "PAYMENT_RECEIPT") != null) {
      load();
    }
  }, [form])

  useEffect(() => {
    setIsMinting(false);

    const names = Object.getOwnPropertyNames(errorForm)

    names.forEach((name) => {
      const el = document.getElementsByName(name)[0];
      if (el != null) {
        el.style.borderColor = 'red';
      }
    })

  }, [errorForm]);

  useEffect(() => {
    const load = async () => {
      var _list = await searchAssets(page, rowsPerPage, order, orderBy, filterName, Number(filterName));
      setTotal(_list.headers["x-total-count"]);
      if (_list) {
        setAssetList(_list.data.filter(a => a !== null));
        setLoading(false);
      }
    }
    load();
  }, [filterName]);

  useEffect(() => {
    const load = async () => {
      var _list = [];
      if (filterName.length > 0) {
        _list = await searchAssets(page, rowsPerPage, order, orderBy, filterName, Number(filterName));
      } else {
        _list = await listAssets(page, rowsPerPage, order, orderBy);
      }
      setTotal(_list.headers["x-total-count"]);
      if (_list) {
        setAssetList(_list.data.filter(a => a !== null));
        setLoading(false);
      }
    }
    load();
  }, [page, rowsPerPage, order, orderBy]);

  return (
    <>
      <Helmet>
        <title> Opere | Registro Arte </title>
      </Helmet>

      {openCrea === false ? (
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Lista Opere
            </Typography>
            <Button onClick={handleOpenMenuCrea} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              Registra opera
            </Button>
          </Stack>

          {loading ? (
            <img src="/assets/icons/Pulse-1s-200px.svg" />
          ) : (
            <Card>
              <UserListToolbar placeholder="Cerca (Titolo, Autore, Descrizione, ID Opera, ID Utente)" showSearchBar={true} numSelected={0} filterName={filterName} onFilterName={handleFilterByName} />

              <Scrollbar>
                <TableContainer>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={AssetList.length}
                      numSelected={0}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {AssetList.map((row) => {
                        const { id, licensePlate, autore, titolo, nft } = row;
                        const { createdDate } = nft | null;
                        const selectedAsset = selected.indexOf(id) !== -1;

                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedAsset}>


                            <TableCell align="left">{licensePlate}</TableCell>

                            <TableCell align="left">{autore}</TableCell>

                            <TableCell align="left">{titolo}</TableCell>

                            <TableCell align="left">{new Date(nft.createdDate).toLocaleDateString('it-IT', { year: "numeric", month: "short", day: "numeric" })}</TableCell>

                            <TableCell align="left">

                              {!loading && (user.authorities.includes('ROLE_ADMIN') || user.authorities.includes('ROLE_SUPERADMIN')) ?

                                <Button onClick={(event) => { handleOpenMenuElimina(event, licensePlate) }} title='Elimina Opera' sx={{ color: 'error.main' }}>
                                  <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />

                                </Button>
                                :

                                <Button style={{ opacity: 0.5 }} disabled >
                                  <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />

                                </Button>

                              }

                              <Button title='Visualizza Opera' onClick={(event) => handleEdit(licensePlate)} >
                                <Iconify icon={'ic:baseline-view-list'} sx={{ mr: 2 }} />

                              </Button>


                              <Button title='Pagina Pubblica' sx={{ color: 'secondary.dark' }}>
                                <a target='blank' href={`/certificate?qr=${licensePlate}`}>
                                  <Iconify icon={'ph:link-bold'} />
                                </a>
                              </Button>

                              <Button title='Visualizza Storico Opera' onClick={(event) => handleHistory(id, licensePlate)} >
                                <Iconify icon={'ic:outline-history'} sx={{ mr: 2 }} />

                              </Button>

                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>

                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Non trovato
                              </Typography>

                              <Typography variant="body2">
                                Nessun risultato trovato per &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Prova a ricontrollare eventuali errori.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          )}
        </Container>

      ) :

        <>
          {/* CREAZIONE OPERA  */}
          <Container>
            <Button onClick={handleCloseMenuCrea} sx={{ mb: 2 }}>
              <Iconify icon={'icon-park-outline:back'} sx={{ mr: 2 }} />
              TORNA INDIETRO
            </Button>
            <Card sx={{ padding: 4 }}>
              <Scrollbar>
                {isEdit ?
                  <Button onClick={handleEditingOpera} endIcon=
                    {isEditing ?
                      <Iconify icon='material-symbols:lock-open' />
                      :
                      <Iconify style={{ opacity: 0.5 }} icon='material-symbols:lock' />
                    }
                  >Modifica</Button>
                  : <></>
                }
                <Typography justifyContent='center' variant="h4" gutterBottom>
                  Informazioni dell' Opera
                </Typography>

                <Typography variant="body1" gutterBottom>
                  * Campi Obbligatori
                </Typography>

                {isEdit ?
                  <Stack direction={mdUp ? 'row' : 'column'} spacing={10}>
                    <Stack>
                      <Typography variant='h4'>QR-Code</Typography>
                      <QRCodeCanvas value={`https://registroarte.it/certificate?qr=${form.licensePlate}`}
                        size={300}
                        bgColor={"#ffffff"}
                        level={"H"}>

                      </QRCodeCanvas>
                    </Stack>
                    <Stack mt={8} spacing={4}>
                      <Button href={certificate} target='blank' size="large" variant="contained" >Attestato PDF</Button>
                      <Button href={polygon} target='blank' size="large" variant="contained" >Transazione</Button>
                      <Button href={rarible} target='blank' size="large" variant="contained" >Visualizza NFT</Button>
                      <Button onClick={(event) => {
                        componentRef.current.hidden = false;
                        handlePrint();
                        componentRef.current.hidden = true;
                      }} size="large" variant="contained" >Scheda PDF</Button>
                    </Stack>
                  </Stack>
                  : <></>}

                {!_.isEmpty(errorForm) ? (
                  <span style={{ color: "#FF4842" }} >Inserire i campi obbligatori.</span>
                ) : (
                  <></>
                )}
                {err !== '' ? (
                  <span style={{ color: "#FF4842" }} >{err}</span>
                ) : (
                  <></>
                )}
                <FormControl sx={{ width: '100%' }}>
                  <Stack mt={1} spacing={2} >

                    <FormControl>
                      <Typography color='text.secondary'>Autore/Autrice *</Typography>
                      <Select disabled={!isEditing} error={errorForm["authorId"]} name='authorId' onChange={handleSelect} value={(form["authorId"] && authors.length > 0) ? form["authorId"] : "default"}>
                        <MenuItem disabled value='default'>
                          {form["autore"]}
                        </MenuItem>
                        {
                          authors.map((author) => (
                            <MenuItem value={author.id}>{author.nome} {author.cognome} ({author.id})</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>

                    <Typography color='text.secondary'>Titolo Opera / Bene *</Typography>
                    <TextField disabled={!isEditing} value={form["titolo"]} error={errorForm["titolo"]} onChange={handleSelect} name="titolo" />
                    <CustomSelect isEditing={isEditing} errorForm={errorForm} altro="ALTRO" form={form} handler={handleSelect} list={categoriaOpts} title="Categoria/Interesse dell'opera *" name="categoria" />
                    <CustomSelect isEditing={isEditing} errorForm={errorForm} altro="ALTRO" form={form} handler={handleSelect} list={tipologiaOpts} title="Tipologia *" name="tipologia" />

                    <FormControl>
                      <Typography color='text.secondary'>Datazione (data fine creazione Opera) *</Typography>
                      <Select disabled={!isEditing} onChange={handleDatazioneScelta} value={datazioneScelta}>
                        <MenuItem disabled value='default'>
                          <em>Scegli un opzione</em>
                        </MenuItem>
                        {datazioneOpts.map((opt) => (
                          <MenuItem value={opt}>{opt}</MenuItem>
                        ))}

                      </Select>
                      {datazioneScelta == "ANNO" ?
                        <TextField disabled={!isEditing} error={errorForm["datazione"]} key={`${"datazioneanno"}-input`} value={anno} onChange={handleAnno}
                          label="Anno *" sx={{ mt: 2 }}
                        />
                        :
                        <></>
                      }
                      {datazioneScelta == "SECOLO" ?
                        <>
                          <FormControl sx={{ mt: 2 }}>
                            <InputLabel>Secolo *</InputLabel>
                            <Select disabled={!isEditing} error={errorForm["datazione"]} className='sottocampo' fullWidth label="Secolo *" onChange={handleSecolo} value={secolo ?? "default"}>
                              <MenuItem disabled value='default'>
                                <em>Scegli un opzione</em>
                              </MenuItem>
                              {secoli.map((opt) => (
                                <MenuItem value={opt}>{opt}</MenuItem>
                              ))}

                            </Select>
                          </FormControl>

                          <FormControl sx={{ mt: 2 }}>
                            <InputLabel>AC/DC *</InputLabel>
                            <Select disabled={!isEditing} className='sottocampo' fullWidth label="AC/DC" onChange={handleAcDc} value={acdc ?? "default"}>
                              <MenuItem disabled value='default'>
                                <em>Scegli un opzione</em>
                              </MenuItem>
                              {['AC', 'DC'].map((opt) => (
                                <MenuItem value={opt}>{opt}</MenuItem>
                              ))}

                            </Select>
                          </FormControl>
                        </>
                        :
                        <></>
                      }
                    </FormControl>

                    <CustomSelect isEditing={isEditing} errorForm={errorForm} altro="ALTRO" form={form} handler={handleSelect} list={materialeOpts} title="Materiale (nelle opere pittoriche equivale a supporto) *" name="materiale" />
                    <CustomSelect isEditing={isEditing} errorForm={errorForm} altro="ALTRO" form={form} handler={handleSelect} list={tecnicaOpts} title="Tecnica *" name="tecnica" />
                    <Typography color='text.secondary'>Dimensioni dell'opera (indicare anche l’unità di misura utilizzata) *</Typography>
                    <TextField disabled={!isEditing} value={form["dimensioni"]} error={errorForm["dimensioni"]} onChange={handleSelect} name="dimensioni" />
                    <Typography color='text.secondary'>Descrizione dell’opera (max 1500 caratteri) *</Typography>
                    <TextField disabled={!isEditing} value={form["descrizione"]} error={errorForm["descrizione"]} onChange={handleSelect} name="descrizione" />
                    <CustomSelect isEditing={isEditing} errorForm={errorForm} altro="ALTRO" form={form} handler={handleSelect} list={provenienzaOpts} title="Provenienza *" name="provenienza" />
                    <CustomSelect isEditing={isEditing} errorForm={errorForm} altro="ALTRO" form={form} handler={handleSelect} list={ubicazioneOpts} title="Ubicazione/esposizione *" name="ubicazione" />

                    <Typography color='text.secondary'>Luogo di conservazione *</Typography>
                    <AutoComplete disabled={!isEditing} form={form} name="luogo" handler={handleForm} />

                    <Typography color='text.secondary'>Diritti d'autore *</Typography>
                    <TextField disabled={!isEditing} value={form["dirittiAutore"]} error={errorForm["dirittiAutore"]} onChange={handleSelect} name="dirittiAutore" />
                    <Typography color='text.secondary'>Diritti di proprietà *</Typography>
                    <TextField disabled={!isEditing} value={form["dirittiProprieta"]} error={errorForm["dirittiProprieta"]} onChange={handleSelect} name="dirittiProprieta" />


                    <CustomSelect isEditing={isEditing} errorForm={errorForm} altro="ALTRO" form={form} handler={handleSelect} list={documentataInOpts} title="Opera documentata in *" name="documentatoIn" />
                    <TextField disabled={!isEditing} className='sottocampo' sx={{
                      display: form["documentatoIn"] != null && form["documentatoIn"] != "" ? 'flex' : 'none'
                    }}
                      onChange={handleSelect} name="nprotocollo" label="Documentata in/ N. PROTOCOLLO *" value={form["nprotocollo"]}
                    />
                    <TextField disabled={!isEditing} className='sottocampo' sx={{
                      display: form["documentatoIn"] != null && form["documentatoIn"] != "" ? 'flex' : 'none'
                    }}
                      onChange={handleSelect} name="indicazioni" label="Documentata in/ Indicazioni sulla raccolta in oggetto *" value={form["indicazioni"]}
                    />


                    <CustomSelect isEditing={isEditing} errorForm={errorForm} altro="DISPONIBILE" form={form} handler={handleSelect} list={['DISPONIBILE', 'NON DISPONIBILE']} title="Certificato di autenticità o perizia rilasciato da *" name="certificato" />
                    <TextField className='sottocampo' sx={{
                      display: form["certificato"] != null && form["certificato"] != "" && form["certificato"] != "NON DISPONIBILE" ? 'flex' : 'none'
                    }}
                      onChange={handleSelect} name="enteCertificato" label="Ente di rilascio certificato *"
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                      <DatePicker
                        format='DD/MM/YYYY'
                        className='sottocampo' sx={{
                          display: form["certificato"] != null && form["certificato"] != "" && form["certificato"] != "NON DISPONIBILE" ? 'flex' : 'none'
                        }}
                        name="dataCertificato" label="Data di rilascio certificato *"
                        onChange={(value) => { handleSelectDate(value, 'dataCertificato') }}
                      />
                    </LocalizationProvider>


                    <CustomSelect isEditing={isEditing} errorForm={errorForm} altro="DISPONIBILE" form={form} handler={handleSelect} list={['DISPONIBILE', 'NON DISPONIBILE']} title="Ultimo valore di mercato attribuito all’opera (espresso in euro) *" name="valore" />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                      <DatePicker
                        format='DD/MM/YYYY'
                        className='sottocampo' sx={{
                          display: form["valore"] != null && form["valore"] != "" && form["valore"] != "NON DISPONIBILE" ? 'flex' : 'none'
                        }}
                        name="dataStima" label="Valore/ data della stima *"
                        onChange={(value) => { handleSelectDate(value, 'dataStima') }}
                      />
                    </LocalizationProvider>
                    <Alert severity="info" sx={{
                      display: form["valore"] != null && form["valore"] != "" && form["valore"] == "NON DISPONIBILE" ? 'flex' : 'none'
                    }}>Richiedi una stima/perizia professionale a <a target='blank' href='https://www.eleonoracoloretti.it/'>questo link</a></Alert>
                    <FormControl className='sottocampo' sx={{
                      display: form["valore"] != null && form["valore"] != "" && form["valore"] != "NON DISPONIBILE" ? 'flex' : 'none'
                    }}>
                      <InputLabel>Valore/ stimato da *</InputLabel>
                      <Select label="Valore/ stimato da *" name="stimatoDa" onChange={(event) => { setValoreOpt(event.target.value) }} value={valoreOpt}>
                        <MenuItem disabled value='default'>
                          <em>Scegli un opzione</em>
                        </MenuItem>
                        {["CASA D'ASTA", 'ALTRO'].map((opt) => (
                          <MenuItem value={opt}>{opt}</MenuItem>
                        ))}

                      </Select>
                      {valoreOpt == 'ALTRO' ?
                        <TextField key={`stimatoDa-input`} value={form["stimatoDa"]} onChange={handleSelect}
                          name="stimatoDa" label="Specifica" sx={{ mt: 2 }}
                        />
                        :
                        <></>
                      }
                      {valoreOpt == "CASA D'ASTA" ?
                        <><TextField key={`stimatoDas-input`} value={form["stimatoDa"]} onChange={handleSelect}
                          name="stimatoDa" label="Nome casa d'asta *" sx={{ mt: 2 }}

                        />
                          <TextField key={`stimatoDas1-input`}
                            label="Valore di battuta (espresso in euro) *" sx={{ mt: 2 }}

                          />
                        </>
                        :
                        <></>
                      }
                    </FormControl>

                    <CustomSelect isEditing={isEditing} errorForm={errorForm} altro="SI" form={form} handler={handleSelect} list={['SI', 'NO']} title="Menzioni, recensioni, citazioni bibliografiche *" name="citazioni" />
                    <TextField className='sottocampo' sx={{
                      display: form["citazioni"] != null && form["citazioni"] != "" && form["citazioni"] != 'NO' ? 'flex' : 'none'
                    }}
                      onChange={handleSelect} name="tipologiaCitazioni" label="Tipologia di Recensioni/menzioni *"
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                      <DatePicker
                        format='DD/MM/YYYY'
                        className='sottocampo' sx={{
                          display: form["citazioni"] != null && form["citazioni"] != "" && form["citazioni"] != "NO" ? 'flex' : 'none'
                        }}
                        name="dataCitazioni" label="Data Recensioni/menzioni *"
                        onChange={(value) => { handleSelectDate(value, 'dataCitazioni') }}
                      />
                    </LocalizationProvider>
                    <TextField className='sottocampo' sx={{
                      display: form["citazioni"] != null && form["citazioni"] != "" && form["citazioni"] != 'NO' ? 'flex' : 'none'
                    }}
                      onChange={handleSelect} name="enteCitazioni" label="Ente di rilascio Recensioni/menzioni *"
                    />

                    <CustomSelect isEditing={isEditing} errorForm={errorForm} altro="ALTRO" form={form} handler={handleSelect} list={['Esposizione temporanea', 'Esposizione permanente', 'NO', 'ALTRO']} title="Opera in esposizione presso *" name="esposizionePresso" />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                      <DatePicker
                        format='DD/MM/YYYY'
                        name='pressoData' className='sottocampo' sx={{
                          display: form["esposizionePresso"] != null && form["esposizionePresso"] != "" && form["esposizionePresso"] == "ALTRO" ? 'flex' : 'none'
                        }}
                        label="Esposizione presso/data" onChange={(value) => { handleSelectDate(value, 'pressoData') }}
                      />
                    </LocalizationProvider>
                    <TextField name='pressoEnte' className='sottocampo' sx={{
                      display: form["esposizionePresso"] != null && form["esposizionePresso"] != "" && form["esposizionePresso"] == "ALTRO" ? 'flex' : 'none'
                    }}
                      onChange={handleSelect} label="Esposizione presso/ente *"
                    />

                    <CustomSelect isEditing={isEditing} errorForm={errorForm} altro="SI" form={form} handler={handleSelect} list={['SI', 'NO']} title="Interventi di conservazione o restauro effettuati *" name="interventi" />
                    <TextField name='enteInterventi' className='sottocampo' sx={{
                      display: form["interventi"] != null && form["interventi"] != "" && form["interventi"] != "NO" ? 'flex' : 'none'
                    }}
                      label="Interventi/Ente restauratore *"
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                      <DatePicker
                        format='DD/MM/YYYY'
                        name='dataInterventi' className='sottocampo' sx={{
                          display: form["interventi"] != null && form["interventi"] != "" && form["interventi"] != "NO" ? 'flex' : 'none'
                        }}
                        label="Interventi/Data restauro *"
                      />
                    </LocalizationProvider>


                    <FormControl>
                      <Typography color='text.secondary'>{definizione}</Typography>
                      <Select disabled={!isEditing} onChange={handleSelect} value={form["beneCulturale"] ?? "default"} error={errorForm["benCulturale"]} name='beneCulturale' label={definizione} >
                        <MenuItem disabled value='default'>
                          <em>Scegli un opzione</em>
                        </MenuItem>
                        <MenuItem value={true}>SI</MenuItem>
                        <MenuItem value={false}>NO</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl>
                      <Typography color='text.secondary'>Opera/bene culturale soggetto a vincolo? *</Typography>
                      <Select disabled={!isEditing} onChange={handleSelect} value={form["vincolo"] ?? "default"} error={errorForm["vincolo"]} name='vincolo' >
                        <MenuItem disabled value='default'>
                          <em>Scegli un opzione</em>
                        </MenuItem>
                        <MenuItem value={true}>SI</MenuItem>
                        <MenuItem value={false}>NO</MenuItem>
                      </Select>
                    </FormControl>

                    {isEdit ?
                      <>
                        <TextField value={form.licensePlate} disabled label="ID Opera (NFT)" />
                        <TextField value={form.user != null ? form.user.person.id : null} disabled label="ID utente" />
                        <TextField value={form.nft != null ? toTimestamp(form.nft.createdDate) : null} disabled label="Data di registrazione" />
                      </>
                      : <></>}

                    <FormControl>
                      <Typography color='text.secondary'>Campo obbligatorio</Typography>
                      <ImageUpload disabled={!isEditing} errorForm={errorForm} name="fronte" imgSrc={isEdit && !isMinting && form.images.find((img) => img.type == "ASSET_PICTURE") != null ? form.images.find((img) => img.type == "ASSET_PICTURE").fileContent.path : null} id="fronte" label="Immagine dell'Opera Fronte (jpg/png) *" />
                    </FormControl>
                    <ImageUpload disabled={!isEditing} errorForm={errorForm} name="retro" imgSrc={isEdit && !isMinting && form.images.find((img) => img.type == "ASSET_PICTURE_RETRO") != null ? form.images.find((img) => img.type == "ASSET_PICTURE_RETRO").fileContent.path : null} id="retro" label="Immagine dell'Opera Retro (jpg/png)" />
                    <FormControl>
                      <Typography color='text.secondary'>Campo obbligatorio</Typography>
                      <ImageUpload disabled={!isEditing} errorForm={errorForm} name="receipt" imgSrc={isEdit && !isMinting && form.images.find((img) => img.type == "PAYMENT_RECEIPT") != null ? form.images.find((img) => img.type == "PAYMENT_RECEIPT").fileContent.path : null} id="receipt" label="Immagine di Fattura/ricevuta di vendita (jpg/png/pdf) *" />
                    </FormControl>
                    <ImageUpload disabled={!isEditing} errorForm={errorForm} name="sings" imgSrc={isEdit && !isMinting && form.images.find((img) => img.type == "PARTICULAR_SIGNS") != null ? form.images.find((img) => img.type == "PARTICULAR_SIGNS").fileContent.path : null} id="signs" label="Immagine di Documentazione Integrativa (jpg/png/pdf)" />

                    {isEditing | !openCrea ?
                      <FormControlLabel control={<Checkbox checked={accettaCondizioni} onChange={handleAccettaCondizioni} />} label={<a target='blank' href='https://registroarte.it/assets/Condizioni_Generali.pdf'>Accetta le Condizioni Generali</a>} />
                      : <></>}
                    <Box mt={8}
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="flex-end">

                      {isMinting ?
                        <Button disabled size='large' variant='contained' >
                          <img src="/assets/icons/Pulse-1s-200px.svg" />
                        </Button>
                        :
                        isEditing | !openCrea ?
                          <Button size='large' variant='contained' onClick={handleCreaOpera} >Crea</Button>
                          : <></>
                      }
                    </Box>

                    {errCondizioni ? (
                      <span style={{ color: "#FF4842" }} >Accettare le condizioni generali.</span>
                    ) : (
                      <></>
                    )}

                  </Stack>
                </FormControl>
                <div style={{ height: 50 }} ></div>
              </Scrollbar>
            </Card>

          </Container >

        </>



      }

      {/* ELIMINA OPERA  */}
      <div>
        <Dialog disableEscapeKeyDown open={openElimina} onClose={handleCloseMenuElimina}>
          <DialogTitle>Elimina Opera</DialogTitle>
          <DialogContent>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Typography>
                  Sei sicuro di voler eliminare l' Opera ?
                </Typography>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMenuElimina}>Annulla</Button>
            <Button sx={{ color: 'error.main' }} onClick={handleOKCloseMenuElimina}>Elimina</Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* SCHEDA PDF  */}
      <table hidden={true} id='stampa' ref={componentRef} >
        <img style={{ marginLeft: '50%', padding: 10 }} src='/assets/icons/registroarte_logo_head.png' />
        <tr>
          <th>Qr-Code</th>
          <QRCodeCanvas value={`https://registroarte.it/certificate?qr=${form.licensePlate}`}
            size={300}
            bgColor={"#ffffff"}
            level={"H"}>

          </QRCodeCanvas>
        </tr>
        <tr>
          <th>Titolo opera/bene</th>
          <td>{form.titolo}</td>
        </tr>
        <tr>
          <th>Autore</th>
          <td>{form.autore}</td>
        </tr>
        <tr>
          <th>Categoria</th>
          <td>{form.categoria}</td>
        </tr>
        <tr>
          <th>Tipologia</th>
          <td>{form.tipologia}</td>
        </tr>
        <tr>
          <th>Materiale</th>
          <td>{form.materiale}</td>
        </tr>
        <tr>
          <th>Tecnica</th>
          <td>{form.tecnica}</td>
        </tr>
        <tr>
          <th>Datazione</th>
          <td>{form.datazione}</td>
        </tr>
        <tr>
          <th>Descrizione</th>
          <td>{form.descrizione}</td>
        </tr>
        <tr>
          <th>Provenienza</th>
          <td>{form.provenienza}</td>
        </tr>
        <tr>
          <th>Diritti d'autore</th>
          <td>{form.dirittiAutore}</td>
        </tr>
        <tr>
          <th>Diritti di proprietà</th>
          <td>{form.dirittiProprieta}</td>
        </tr>
        <tr>
          <th>Dimensioni</th>
          <td>{form.dimensioni}</td>
        </tr>
        <tr>
          <th>Ubicazione</th>
          <td>{form.ubicazione}</td>
        </tr>
        <tr>
          <th>Luogo</th>
          <td>{form.luogo}</td>
        </tr>
        <tr>
          <th>Documentato in</th>
          <td>{form.documentatoIn}</td>
        </tr>
        <tr>
          <th>Numero protocollo</th>
          <td>{form.nprotocollo}</td>
        </tr>
        <tr>
          <th>Indicazioni</th>
          <td>{form.indicazioni}</td>
        </tr>
        <tr>
          <th>Certificato</th>
          <td>{form.certificato}</td>
        </tr>
        <tr>
          <th>Ente certificato</th>
          <td>{form.enteCertificato}</td>
        </tr>
        <tr>
          <th>Data certificato</th>
          <td>{form.dataCertificato}</td>
        </tr>
        <tr>
          <th>Citazioni</th>
          <td>{form.citazioni}</td>
        </tr>
        <tr>
          <th>Esposizione presso</th>
          <td>{form.esposizionePresso}</td>
        </tr>
        <tr>
          <th>Presso ente</th>
          <td>{form.pressoEnte}</td>
        </tr>
        <tr>
          <th>Presso data</th>
          <td>{form.pressoData}</td>
        </tr>
        <tr>
          <th>Presso luogo</th>
          <td>{form.pressoLuogo}</td>
        </tr>
        <tr>
          <th>Interventi</th>
          <td>{form.interventi}</td>
        </tr>
        <tr>
          <th>Data interventi</th>
          <td>{form.dataInterventi}</td>
        </tr>
        <tr>
          <th>Ente interventi</th>
          <td>{form.enteInterventi}</td>
        </tr>
        <tr>
          <th>Vincolo</th>
          <td>{form.vincolo}</td>
        </tr>
        <tr>
          <th>Bene culturale</th>
          <td>{form.beneCulturale}</td>
        </tr>
        <tr>
          <th>Valore</th>
          <td>{form.valore}</td>
        </tr>
        <tr>
          <th>Data stima</th>
          <td>{form.dataStima}</td>
        </tr>
        <tr>
          <th>Stimato da</th>
          <td>{form.stimatoDa}</td>
        </tr>
        <tr>
          <th>ID Opera (NFT)</th>
          <td>{form.licensePlate}</td>
        </tr>
        <tr>
          <th>ID Utente</th>
          <td>{form.user != null ? form.user.person.id : null}</td>
        </tr>
        <tr>
          <th>Data di registrazione</th>
          <td>{form.nft != null ? toTimestamp(form.nft.createdDate) : null}</td>
        </tr>
        <tr>
          <th>Immagine fronte</th>
          <img src={isEdit && !isMinting && form.images.find((img) => img.type == "ASSET_PICTURE") != null ? form.images.find((img) => img.type == "ASSET_PICTURE").fileContent.path : null} />
        </tr>
        <tr>
          <th>Immagine retro</th>
          <img src={isEdit && !isMinting && form.images.find((img) => img.type == "ASSET_PICTURE_RETRO") != null ? form.images.find((img) => img.type == "ASSET_PICTURE_RETRO").fileContent.path : null} />
        </tr>
        <tr>
          <th>Immagine ricevuta</th>
          <img src={isEdit && !isMinting && form.images.find((img) => img.type == "PAYMENT_RECEIPT") != null ? form.images.find((img) => img.type == "PAYMENT_RECEIPT").fileContent.path : null} />
        </tr>
        <tr>
          <th>Immagine segni particolari</th>
          <img src={isEdit && !isMinting && form.images.find((img) => img.type == "PARTICULAR_SIGNS") != null ? form.images.find((img) => img.type == "PARTICULAR_SIGNS").fileContent.path : null} />
        </tr>
      </table>

    </>
  );
}